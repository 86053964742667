import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  globalStyle: {
    height: '100vh',
    overflow: 'scroll',
    width: '820px',
    maxWidth: '100%',
    padding: '.5rem 1rem',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    '& *': {
      fontStyle: 'none',
      color: 'inherit',
      textDecoration: 'none',
    },
    '& a': {
      fontStyle: 'none',
      textDecoration: 'none',
    },
    '& p': {
      padding: '1rem',
    },
    '& ul': {
      padding: '1rem 1.5rem',
    },
    '& h1': {
      marginTop: '1rem',
    },
    '& h2, h3, h4': {
      marginTop: '.5rem',
    },
    '& h1, h2, h3, h4, h5, h6': {
      position: 'relative',
    },
  },
  content: {
    maxHeight: 500,
  },
});
