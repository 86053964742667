import { CircularProgress } from '@material-ui/core';

import { useStyles } from './styles';

export function Fallback(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
}
