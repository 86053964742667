import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { FiberManualRecord } from '@material-ui/icons';
import { useStyles } from './styles';

interface IsOnProps {
  isOn: boolean;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  size?: number;
  model: string;
}

export function IsLocked({
  isOn,
  icon = FiberManualRecord,
  size = 10,
  model,
}: IsOnProps) {
  const classes = useStyles();
  const Icon = icon;
  if (isOn === undefined) return <>-</>;
  let isOnline = isOn;
  if (model === 'LOGALL_INVERTED' || model === 'E3_INVERTED') {
    isOnline = !isOnline;
  }
  return (
    <Icon
      className={isOnline ? classes.onLine : classes.offLine}
      style={{ fontSize: size }}
    />
  );
}
