/* eslint-disable eqeqeq */
interface GetStyleProps {
  ignition: boolean;
  satellite: number;
  signal: number;
  voltage: number;
  bateryPercent: number;
}

const palette = {
  red: '#FF6961',
  green: '#77dd77',
  yellow: '#FFC300',
};

export function getStyle({
  satellite,
  ignition,
  bateryPercent,
  signal,
  voltage,
}: GetStyleProps) {
  const styles = {
    satellite: '',
    ignition: '',
    bateryPercent: '',
    signal: '',
    voltage: '',
  };

  styles.ignition = ignition ? palette.green : palette.red;

  if (voltage == 0) {
    styles.voltage = palette.red;
  } else if (voltage < 10) {
    styles.voltage = palette.yellow;
  } else {
    styles.voltage = palette.green;
  }

  if (signal == 0) {
    styles.signal = palette.red;
  } else if (signal < 25) {
    styles.signal = palette.yellow;
  } else {
    styles.signal = palette.green;
  }

  if (bateryPercent == 0) {
    styles.bateryPercent = palette.red;
  } else if (bateryPercent < 25) {
    styles.bateryPercent = palette.yellow;
  } else {
    styles.bateryPercent = palette.green;
  }

  if (satellite == 0) {
    styles.satellite = palette.red;
  } else if (satellite < 6) {
    styles.satellite = palette.yellow;
  } else {
    styles.satellite = palette.green;
  }

  return styles;
}
