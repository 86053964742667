import { useStyles } from './styles';

export function SatelliteSignal({ signal }) {
  const classes = useStyles();
  if (signal === undefined) return <>-</>;
  if (!Number(signal))
    return <strong className={classes.noGps}>SEM GPS</strong>;
  if (Number(signal) < 6) {
    return <strong className={classes.lowGps}>{signal}</strong>;
  }
  return <strong className={classes.highGps}>{signal}</strong>;
}
