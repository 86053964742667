/* eslint-disable camelcase */
import Api from './api.ts';

export const DeviceTrackerService = {
  create: (company_id, data) => Api.post(`/device_tracker/${company_id}`, data),
  createV1: data => Api.post(`/api/v1/device_tracker`, data),
  update: (id, data) => Api.put(`/device_tracker/${id}`, data),
  delete: id => Api.delete(`/device_tracker/${id}`),
  list: company_id => Api.get(`/device_tracker/company/${company_id}`),
  find: (collumn, payload, company_id) =>
    Api.get('/positions/find/tracker_position', {
      params: { collumn, payload, company_id },
    }),
  lastPosition: deviceId => Api.get(`/device/${deviceId}`),
  search: ({ column, payload, company_id, search_all_companies }) =>
    Api.get(`/device_tracker/search`, {
      params: { column, payload, company_id, search_all_companies },
    }),
  listAllWithHistory: companyId =>
    Api.get(`/device_history/company/${companyId}`),
  listAllDevicesWithPosition: companyId =>
    Api.get(`/device_tracker/list/all/${companyId}`),
  getDeviceInfoByImei: ({ imei, address }) =>
    Api.get('device_tracker/get_device_info', { params: { imei, address } }),
  transferDevice: ({ companyId, imeis, newCompanyId }) =>
    Api.patch(`device_tracker/transferDevice`, {
      companyId,
      imeis,
      newCompanyId,
    }),
  transferDeviceToCompany: ({ imeis, newCompanyId }) =>
    Api.patch(`api/v1/device_tracker/move-to-company/${newCompanyId}`, {
      imeis,
    }),
  transferDeviceToStock: ({ imei }) =>
    Api.patch(`api/v1/device_tracker/${imei}/move-to-stock`),
  transferDeviceToMainStock: ({ imeis }) =>
    Api.patch('api/v1/device_tracker/move-to-main-stock', {
      imeis,
    }),
  transferDeviceToInstallerStock: ({ imeis, installerId }) =>
    Api.patch(`api/v1/device_tracker/move-to-installer/${installerId}`, {
      imeis,
    }),
  associateToVehicle: (imei, data) =>
    Api.patch(`api/v1/device_tracker/associate-to-vehicle/${imei}`, data),
};
