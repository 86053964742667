import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useGetLastTreatmentByVehicleId } from 'pages/Map/api/getLastTreatmentByVehicleId';

interface TooltipInfoProps {
  vehicleId: string;
}

const typeOptions = [
  { value: '', label: 'Selecione' },
  { value: 'active', label: 'Ativo' },
  { value: 'receptive', label: 'Receptivo' },
];

const synthesisOptions = [
  { value: '', label: 'Selecione' },
  { value: 'scheduling', label: 'Agendamento' },
  { value: 'maintenance', label: 'Manutenção' },
  { value: 'complaint', label: 'Reclamação' },
  { value: 'password_delivery', label: 'Liberação de senha' },
  { value: 'withdrawal', label: 'Retirada' },
  { value: 'simpleContact', label: 'Simples contato' },
  { value: 'installation', label: 'Instalação' },
  { value: 'financial', label: 'Financeiro' },
  { value: 'unsuccessful', label: 'Sem sucesso' },
  { value: 'register', label: 'Cadastro' },
  { value: 'theft', label: 'Roubo' },
];

function translate(
  value: string | undefined,
  options: { value: string; label: string }[],
): string {
  if (value === undefined) {
    return '';
  }
  const option = options.find(opt => opt.value === value);
  return option ? option.label : value;
}

export function TooltipInfo({ vehicleId }: TooltipInfoProps) {
  const { data, isLoading } = useGetLastTreatmentByVehicleId(vehicleId);

  if (isLoading) {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton width={250} />
        <Skeleton width={250} />
        <Skeleton width={250} />
        <Skeleton width={250} />
        <Skeleton width={250} />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Nenhum tratamento encontrado.</span>
      </Box>
    );
  }

  return (
    <Box>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box>Data: {new Date(data?.treatmentDate).toLocaleString()}</Box>
        <Box>Usuário: {data?.user?.name}</Box>
        <Box>Tipo: {translate(data?.type, typeOptions)}</Box>
        <Box>Síntese: {translate(data?.synthesis, synthesisOptions)}</Box>
        <Box>Nome do contato: {data?.contactedName}</Box>
        <Box>Telefone contatado: {data?.contactedPhone}</Box>
        <Box>Observações: {data?.obs}</Box>
      </Box>
    </Box>
  );
}
