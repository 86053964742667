import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IDecodedToken } from 'services/sharedTracker';
import { VerifyToken } from './VerifyToken';
import { SharedTrackMap } from './SharedTrackerMap';
import { useStyles } from './styles/globalShareTracker';

interface ITokenParms {
  token: string;
}

export function SharedTracking() {
  const [decodedToken, setDecodedToken] = useState<IDecodedToken>();
  const classes = useStyles();
  const { token } = useParams<ITokenParms>();
  const wakeLockRef = useRef<any>();

  const handleDecodedToken = useCallback((data: IDecodedToken) => {
    setDecodedToken(data);
  }, []);

  const handleInvalidateToken = useCallback(() => {
    setDecodedToken(old => {
      return { ...old, expired: true } as IDecodedToken;
    });
  }, []);

  useEffect(() => {
    const wakeLock = async () => {
      try {
        const anyNav: any = navigator;
        wakeLockRef.current = await anyNav.wakeLock.request();
        // wakeLockRef.current.addEventListener('release', () => {
        //   console.log(
        //     'Screen Wake State Locked release:',
        //     !wakeLockRef.current.released,
        //   );
        // });
      } catch (e: any) {
        console.error('Failed to lock wake state with reason:', e.message);
      }
    };

    const releaseWakeLock = async () => {
      if (wakeLockRef.current) {
        try {
          await wakeLockRef.current.release();
        } catch (e: any) {
          console.log('Failed release lock', e.message);
        }
      }
    };

    if ('wakeLock' in navigator) {
      wakeLock();
    }

    return () => {
      if ('wakeLock' in navigator) {
        releaseWakeLock();
      }
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className={classes.map}>
        {decodedToken && !decodedToken.expired ? (
          <SharedTrackMap
            decodedToken={decodedToken}
            handleInvalidateToken={handleInvalidateToken}
          />
        ) : (
          <VerifyToken token={token} handleDecodedToken={handleDecodedToken} />
        )}
      </div>
    </div>
  );
}
