import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  timerContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    border: '1px solid rgba(204, 204, 204, 0.2)',
    borderRadius: '0px 10px 10px 0px',
    top: '2.5rem',
    width: '10em',
    background: '#09ACFB',
  },
  timerContent: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 700,
  },
  fixedButtonContainer: {
    position: 'fixed',
    left: 0,
    top: 300,
    marginLeft: 10,
  },
});
