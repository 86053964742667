import React, { useCallback, useState, useEffect } from 'react';
import {
  Switch,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Box,
} from '@material-ui/core';
import {
  BrightnessHigh,
  Brightness3,
  Settings,
  ExitToApp,
} from '@material-ui/icons';

import { useAuth } from 'context/authProvider';

import { useCustomTheme } from 'context/themeProvider';
import { useMobileResolution } from 'hooks/useMobileResolution';
import { LocalThemeProvider } from 'context/LocalThemeProvider';
import { useStyles } from './styles';
import { ProfileSection } from '../components/ProfileSection';

export function SettingsDialog() {
  const isMobile = useMobileResolution();
  const classes = useStyles({ isMobile });
  const [switchChecked, setSwitchChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout } = useAuth();
  const { setTheme, appTheme } = useCustomTheme();

  const toggleTheme = useCallback(
    e => {
      const { checked } = e.target;
      const currentTheme = checked ? 'dark' : 'light';
      setTheme(currentTheme);

      setSwitchChecked(checked);
    },
    [setTheme],
  );

  const handleOpenSettings = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleCloseSettings = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleOpenProfileModal = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (appTheme === 'dark') {
      setSwitchChecked(true);
    }
  }, [appTheme]);

  return (
    <Box className={classes.settings}>
      <Tooltip title="Menu">
        <IconButton
          aria-label="settings menu"
          component="a"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenSettings}
          size="small"
        >
          <Settings fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseSettings}
      >
        <MenuItem>
          <strong>{user && user.login}</strong>
        </MenuItem>
        <MenuItem>
          <Switch
            color="default"
            checked={switchChecked}
            onChange={toggleTheme}
            icon={<BrightnessHigh fontSize="small" color="primary" />}
            checkedIcon={<Brightness3 fontSize="small" />}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          Tema{' '}
        </MenuItem>
        <LocalThemeProvider>
          <ProfileSection onClick={handleOpenProfileModal} />
        </LocalThemeProvider>
        <MenuItem
          onClick={() => {
            handleCloseSettings();
            logout();
          }}
        >
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
