import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  fixedButtonStyles: {
    all: 'unset',
    backgroundColor: '#2A99D7',
    color: '#f4f1f1',
    boxShadow: '#060606 0px 2px 8px 0px',
    border: '1px solid rgba(8, 189, 249, 0.7)',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    display: 'grid',
    placeContent: 'center',
    cursor: 'pointer',
  },
});
