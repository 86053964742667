import {
  BatteryChargingFull,
  FlashOn,
  Language,
  PowerSettingsNew,
  Schedule,
  Speed,
  Wifi,
} from '@material-ui/icons';
import { addHours } from 'date-fns';
import { format } from 'date-fns/esm';
import { formatDistanceDate } from 'utils/formatData';
import { getStyle } from './styles';

interface TraceInfoWindowContentProps {
  ignition: boolean;
  speed: number;
  dateTime: [Date, Date?];
  satellite: number;
  signal: number;
  voltage: number;
  bateryPercent: number;
  count: number;
  index: number;
}

export const TraceInfoWindowContent = ({
  speed,
  dateTime,
  index,
  ignition,
  satellite,
  bateryPercent,
  signal,
  voltage,
  count,
}: TraceInfoWindowContentProps) => {
  let timeStopped: null | string = null;
  const styles = getStyle({
    bateryPercent,
    ignition,
    satellite,
    signal,
    voltage,
  });

  if (dateTime[1]) {
    timeStopped = formatDistanceDate(
      new Date(dateTime[1]),
      new Date(dateTime[0]),
    );
  }

  const date1 = format(
    addHours(new Date(dateTime[0]), 3),
    'dd/MM/yyyy HH:mm:ss',
  );
  const date2 = dateTime[1]
    ? format(addHours(new Date(dateTime[1]), 3), 'dd/MM/yyyy HH:mm:ss')
    : null;

  return (
    <div style={{ color: 'black' }}>
      <strong>#{index}</strong> {count > 1 && <span>{count}x</span>}
      {timeStopped ? (
        <>
          <br /> <br />
          <span>Parado {timeStopped}</span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 5 }}>
              <Schedule fontSize="small" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{`das ${date1}`}</span>
              <span>{`às⠀${date2}`}</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <PowerSettingsNew
              fontSize="small"
              style={{ color: styles.ignition }}
            />
            <Wifi fontSize="small" style={{ color: styles.satellite }} />
            {satellite}
            <Language fontSize="small" style={{ color: styles.signal }} />
            {signal}%
            <BatteryChargingFull
              fontSize="small"
              style={{ color: styles.bateryPercent, marginRight: -5 }}
            />
            {bateryPercent}%
            <FlashOn
              fontSize="small"
              style={{ color: styles.voltage, marginRight: -5 }}
            />
            {voltage}V
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Speed fontSize="small" style={{ marginRight: 5 }} />
            {speed}km/h
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 5 }}>
              <Schedule fontSize="small" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{date1}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
