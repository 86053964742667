import {
  MenuItem,
  ListItemIcon,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { UiModal, UiModalRef } from 'components/UI';
import { useAuth } from 'context/authProvider';
import { useMemo, useRef, useState } from 'react';
import { ChangePassword } from '../ChangePassword';
import { useProfileStyle } from './style';

type Props = {
  onClick: () => void;
};

export function ProfileSection({ onClick }: Props) {
  const profileModalRef = useRef<UiModalRef>(null);
  const { user } = useAuth();
  const { role, status } = user;
  const { name, description } = role;
  const [changePasswordMode, setChangePasswordMode] = useState(false);
  const translatedStatus = useMemo(() => {
    switch (status) {
      case 'active':
        return 'ativo';
      case 'inactive':
        return 'inativo';
      default:
        return 'ativo';
    }
  }, [status]);

  const classes = useProfileStyle();
  return (
    <>
      <UiModal
        title="Perfil"
        ref={profileModalRef}
        onClose={() => setChangePasswordMode(false)}
      >
        <Box component="section" className={classes.mainInfos}>
          <img
            className={classes.profilePic}
            alt="iamgem padrão"
            src="defaultProfilePicture.jpg"
          />
          <section className={classes.statsInfo}>
            <Typography
              style={{ fontWeight: 500, lineHeight: '115%' }}
              variant="h2"
            >
              {name}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                opacity: 0.3,
                lineHeight: '150%',
                marginTop: 6,
              }}
            >
              {description}
            </Typography>
            <Typography className={classes.status} data-status={status}>
              {translatedStatus}
            </Typography>
          </section>
        </Box>
        <section className={classes.buttonArea}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setChangePasswordMode(oldValue => !oldValue)}
          >
            Alterar senha
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#f44336' }}
            onClick={() => profileModalRef.current?.close()}
          >
            Fechar
          </Button>
        </section>
        {changePasswordMode ? (
          <ChangePassword
            onFinish={() => {
              profileModalRef.current?.close();
              setChangePasswordMode(false);
            }}
          />
        ) : null}
      </UiModal>
      <MenuItem
        onClick={() => {
          onClick();
          profileModalRef.current?.open();
        }}
      >
        <ListItemIcon>
          <Person style={{ paddingBottom: 2 }} />
        </ListItemIcon>
        Perfil
      </MenuItem>
    </>
  );
}
