import { useRef, useState, useEffect } from 'react';
import { CardInfoProps } from '../CardCarInfo';

const formatTime = (timer: number) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = Math.floor(timer / 60);
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};
export function TimerContainer({
  handleInvalidateToken,
  expiresIn,
}: CardInfoProps) {
  const timerRef = useRef<any>();
  const [timeLeft, setTimeLeft] = useState(() => {
    return Math.round(expiresIn - Date.now() / 1000);
  });
  useEffect(() => {
    const ONE_SECOND = 1000;

    timerRef.current = setInterval(() => {
      setTimeLeft(oldTime => oldTime - 1);
    }, ONE_SECOND);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) handleInvalidateToken();
  }, [timeLeft, handleInvalidateToken]);

  return (
    <div>
      <p>
        Tempo para expirar o link <br />
        {formatTime(timeLeft)}
      </p>
    </div>
  );
}
