import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { FiberManualRecord } from '@material-ui/icons';
import { useStyles } from './styles';

interface IsOnProps {
  isOn: boolean;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  size?: number;
}

export function IsOn({ isOn, icon = FiberManualRecord, size = 10 }: IsOnProps) {
  const classes = useStyles();
  const Icon = icon;
  if (isOn === undefined) return <>-</>;

  return (
    <Icon
      className={isOn ? classes.onLine : classes.offLine}
      style={{ fontSize: size }}
    />
  );
}
