import { Box, Tooltip } from '@material-ui/core';

import { useRef, useState } from 'react';
import { TooltipInfo } from './TooltipInfo';

interface TreatmentGridProps {
  vehicleId: string;
}

export function TreatmentGrid({ vehicleId }: TreatmentGridProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const refetched = useRef(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
    refetched.current = false;
  };

  return (
    <Box>
      <Tooltip
        title={<>{isTooltipOpen && <TooltipInfo vehicleId={vehicleId} />}</>}
        placement="left"
        arrow
        enterDelay={500}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
      >
        <Box>Último tratamento</Box>
      </Tooltip>
    </Box>
  );
}
