import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => ({
  subInfoEventsContainer: {
    background: '#F0F0F0',
    width: '95%',
    padding: '10px 20px',
    borderRadius: '20px',
    display: 'grid',
    margin: 'auto',
  },
  subInfoContent: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gap: 4,
    marginBottom: 8,
    justifyContent: 'start',
  },
  titleSubInfosCard: {
    color: '#2C9BE4',
    fontWeight: 700,
    fontSize: 12,
    paddingBlock: 0,
    paddingInline: 0,
    marginBlock: 0,
    display: 'flex',
    alignItems: 'center',
  },
  textEvents: {
    background: '#F28383',
    fontSize: 12,
    color: '#fff',
    padding: '2px 8px',
    borderRadius: 12,
    fontWeight: 700,
    textAlign: 'center',
  },
  sectionCardInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  text: {
    all: 'unset',
    color: '#676767',
    fontWeight: 700,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '200px',
  },
  icon: {
    color: '#676767',
    fontSize: '20px',
  },
}));
