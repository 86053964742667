import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  root: {
    width: '100%',
    height: '100%',
  },
  map: {
    width: '100%',
    position: 'absolute',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
    },
  },
}));
