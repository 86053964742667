import { ThemeProvider } from '@material-ui/core';
import { createLocalTheme } from 'config/theme';
import { ReactNode, useEffect, useState } from 'react';
import { storage } from 'utils';
import { useAuth } from './authProvider';

type Props = {
  children: ReactNode;
};

export function LocalThemeProvider({ children }: Props) {
  const { user } = useAuth();
  const [appTheme, setAppTheme] = useState<'dark' | 'light'>(() => {
    const settings = storage.getItem({
      key: 'settings',
      storageType: 'session',
    });

    return settings?.theme ?? 'light';
  });

  useEffect(() => {
    if (user.userSettings?.theme) setAppTheme(user.userSettings?.theme);
  }, [user.userSettings?.theme]);

  const theme = createLocalTheme(appTheme);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
