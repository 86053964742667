import { Component } from 'react';
import { Container, Paper } from '@material-ui/core';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error: true, info: error };
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <Container
          style={{
            display: 'flex',
            margin: '0 auto',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Paper>
            <h3>Desculpe, algo de errado aconteceu!</h3>
          </Paper>
        </Container>
      );
    }
    return children;
  }
}
