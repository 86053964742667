import { useState, useEffect } from 'react';
import { Can } from 'components/Can';
import { List, ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import { useNavigation, NavItems } from 'context/navigationProvider';
import { useHistory } from 'react-router-dom';
import { useMobileResolution } from 'hooks/useMobileResolution';
import { useAuth } from 'context/authProvider';
import { useStyles } from './styles';

export function MenuLinks() {
  const [currentPath, setCurrentPath] = useState('');
  const { navItems } = useNavigation();
  const history = useHistory();
  const isMobileResolution = useMobileResolution();
  const {
    user: { isMasterCompany },
  } = useAuth();
  const isMaster = !!isMasterCompany;
  const classes = useStyles({ isMobile: isMobileResolution });
  const { listen, location } = useHistory();

  const menuItems: NavItems[] = navItems.filter(route =>
    isMobileResolution
      ? route.sideBar && route.isMobile
      : route.sideBar && !route.isOnlyMobile,
  );
  listen(path => {
    setCurrentPath(path.pathname);
  });

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [isMobileResolution, location]);

  return (
    <menu className={classes.menu}>
      {menuItems.map(
        ({
          id,
          name,
          icon,
          can,
          allowedCompanies,
          blockedCompanies,
          path,
          // allowedUsers,
          // blockedUsers,
        }) => (
          <Can
            key={id}
            permissions={can}
            // allowedUsers={allowedUsers}
            // blockedUsers={blockedUsers}
            allowedCompanies={allowedCompanies}
            blockedCompanies={blockedCompanies}
          >
            <List component="ul">
              {path === '/dashboard' && isMobileResolution && !isMaster ? (
                <></>
              ) : (
                <Tooltip title={name} placement="right" arrow interactive>
                  <ListItem
                    component="a"
                    alignItems="center"
                    button
                    className={classes.listItem}
                    disableGutters
                    selected={currentPath === path}
                    onClick={() => {
                      history.push(path);
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.listIcon }}>
                      {icon}
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
            </List>
          </Can>
        ),
      )}
    </menu>
  );
}
