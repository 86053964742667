import Api from './api';

interface HistoryPaginationProps {
  page: number;
  size: number;
  imei: string;
  initialDate: Date;
  finalDate: Date;
}

interface PositionToAddressProps {
  latitude: string;
  longitude: string;
}

interface GetTraceProps {
  imei: string;
  hours?: number;
  betweenDates?: [string, string];
  limitPositions?: number;
  month: number;
  year: number;
}

export const PositionsService = {
  /**
   * @deprecated replaced for historyPaginationTimeSeries
   */
  historyPagination: ({
    page,
    size,
    imei,
    initialDate,
    finalDate,
  }: HistoryPaginationProps) =>
    Api.get(`/positions/history_addresses`, {
      params: {
        column: 'imei',
        payload: imei,
        initialDate,
        finalDate,
        page,
        size,
      },
    }),
  historyPaginationTimeSeries: ({
    page,
    size,
    imei,
    initialDate,
    finalDate,
  }: HistoryPaginationProps) =>
    Api.get(`/positions_timeseries/history_addresses`, {
      params: {
        column: 'imei',
        payload: imei,
        initialDate,
        finalDate,
        page,
        size,
      },
    }),

  /**
   * @deprecated replaced for paginatePositionsOnlyTimeSeries
   */
  paginatePositionsOnly: ({
    page,
    imei,
    size,
    initialDate,
    finalDate,
  }: HistoryPaginationProps) =>
    Api.get(`/positions/history_only`, {
      params: {
        initialDate,
        finalDate,
        page,
        size,
        column: 'imei',
        payload: imei,
      },
    }),
  paginatePositionsOnlyTimeSeries: ({
    page,
    imei,
    size,
    initialDate,
    finalDate,
  }: HistoryPaginationProps) =>
    Api.get(`/positions_timeseries/history_only`, {
      params: {
        initialDate,
        finalDate,
        page,
        size,
        column: 'imei',
        payload: imei,
      },
    }),
  lastPosition: (deviceId: string) =>
    Api.get(`/positions/last_position/${deviceId}`),
  lastPositionByImeis: (deviceIds: string[]) =>
    Api.get(`/positions/last_position_by_imeis/only_positions`, {
      params: { imeis: deviceIds },
    }),
  communication: (companyId: number, hours: number) =>
    Api.get(`/positions/communication/${companyId}/${hours}`),
  positionToAddress: ({ latitude, longitude }: PositionToAddressProps) =>
    Api.get('/geocode', { params: { latitude, longitude } }),
  getTraceTimeSeries: ({
    imei,
    hours,
    betweenDates,
    limitPositions,
    month,
    year,
  }: GetTraceProps) =>
    Api.get(`/positions_timeseries/trace/${imei}`, {
      params: { hours, betweenDates, limitPositions, month, year },
    }),
};
