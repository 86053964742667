import { makeStyles, Theme } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
  currentTheme: 'dark' | 'light';
};

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    background: ({ currentTheme, isMobile }) =>
      currentTheme === 'dark'
        ? theme.palette.background.paper
        : `linear-gradient(${
            isMobile ? '90deg' : '180deg'
          }, #01E6E0 -35.19%, #0C76D3 64.8%)`,
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'row' : 'column'),
    position: 'sticky',
    top: ({ isMobile }) => (isMobile ? 'auto' : 0),
    bottom: ({ isMobile }) => (isMobile ? 0 : 'auto'),
    width: ({ isMobile }) => (isMobile ? '100vw' : 40),
    height: ({ isMobile }) => (isMobile ? 'auto' : '100vh'),
    justifyContent: ({ isMobile }) => (isMobile ? 'space-between' : 'normal'),
    borderRadius: 0,
    zIndex: 5,

    '& .MuiIconButton-root, & .MuiListItemIcon-root': {
      color: '#fff',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginTop: ({ isMobile }) => (isMobile ? 0 : 8),
  },
  navigation: {
    height: '100%',
    display: 'flex',
    width: ({ isMobile }) => (isMobile ? '50%' : 'auto'),
    flexDirection: ({ isMobile }) => (isMobile ? 'row' : 'column'),
    justifyContent: 'space-between',
  },
}));
