import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    subInfosCardContent: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      all: 'unset',
      fontSize: 10,
      color: '#7D7D7D',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      gap: '2px',
      [theme.breakpoints.down(450)]: {
        gap: 4,
        width: 75,
        fontSize: 10,
      },
    },
    sectionCardInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
  }),
);
