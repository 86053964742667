import { Container, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    minWidth: 800,
    minHeight: 800,
    textAlign: 'center',
  },
});

export function NotFound() {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Paper className={classes.content}>
        <Typography variant="h4">404 - Página não encontrada.</Typography>
      </Paper>
    </Container>
  );
}
