import { Box, IconButton } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useStyles } from './styles';

export type PersonMarkerProps = {
  lat?: number | string;
  lng?: number | string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PersonMarker(_: PersonMarkerProps) {
  const classes = useStyles();

  return (
    <Box className={classes.root} component="button">
      <IconButton className={classes.iconButton}>
        <LocationOnIcon className={classes.iconPersonMarker} fontSize="large" />
      </IconButton>
    </Box>
  );
}
