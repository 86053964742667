import axios from 'axios';
import { REACT_APP_API_URL } from '../config';

type PayloadInfos = {
  new_password: string;
  password_confirmation: string;
  password: string;
};

const Api = axios.create({
  baseURL: REACT_APP_API_URL,
});
function getToken() {
  const localToken = localStorage.getItem('@light_web_admin:token');
  const sessionToken = sessionStorage.getItem('@light_web_admin:token');

  if (localToken) {
    return JSON.parse(localToken);
  }

  if (sessionToken) {
    return JSON.parse(sessionToken);
  }

  return null;
}

export async function changePassword(data: PayloadInfos) {
  const token = getToken();
  await Api.patch('/users_settings/change_password', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return true;
}
