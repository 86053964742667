import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { fullShare: boolean }>(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  cardHeaderContent: {
    padding: '10px',
    height: '60px',
    marginBottom: '-5px',
  },
  cardInfoContent: {
    width: '100%',
    maxWidth: '25rem',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'visible',
    marginRight: 'auto',
    marginLeft: 'auto',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '25px',
    transition: 'transform 0.5s',
    [theme.breakpoints.down(450)]: {
      width: '100%',
      bottom: 0,
      borderRadius: '25px 25px 0px 0px',
    },
  },
  hideCard: {
    bottom: 0,
    height: '40%',
    maxHeight: '270px',
    width: '100%',
    maxWidth: '400px',
    transform: 'translateY(215px)',
    [theme.breakpoints.down(1025)]: {
      height: '45%',
      bottom: 5,
    },
  },
  ButtonStyles: {
    all: 'unset',
    backgroundColor: '#2A99D7',
    color: '#f4f1f1',
    boxShadow: '#060606 0px 2px 8px 0px',
    border: '1px solid rgba(8, 189, 249, 0.7)',
    borderRadius: '50%',
    fontSize: '22px',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    placeContent: 'center',
    cursor: 'pointer',
    marginTop: '4px',
    '& > *': {
      margin: 'auto',
      padding: '0',
    },
  },
  buttonHideCard: {
    width: '10rem',
    border: '1px solid #65656529',
    background: '#65656557',
    position: 'absolute',
    borderRadius: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: '5px',
  },
  fixedButtonContainer: {
    position: 'absolute',
    right: 0,
    top: '-60px',
  },
  hideButtonClose: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(321)]: {
      position: 'relative',
      right: 7,
    },
  },
  buttonMobile: {
    border: 'none',
    background: 'none',
    width: '100%',
    borderRight: '1px solid #D6D6D6',
    borderLeft: ({ fullShare }) => (fullShare ? '1px solid #D6D6D6' : 'none'),
    [theme.breakpoints.down(325)]: {
      display: 'grid',
      position: 'relative',
      right: 10,
    },
  },
  plateStyle: {
    textAlign: 'center',
    fontSize: 14,
    color: '#4f4e4e',
    [theme.breakpoints.down(450)]: {
      position: 'relative',
      top: '5px',
    },
  },
  vehicleInfoCardContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '5px',
    marginTop: '5px',
  },
  containerButtonPlate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    left: ({ fullShare }) => (fullShare ? 'none' : 6),
  },
}));
