import { createTheme } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { ptBR } from '@material-ui/core/locale';

type PaletteMode = 'light' | 'dark';

const getDesignTokens = (mode: PaletteMode): PaletteOptions => ({
  type: mode,
  primary: {
    main: '#2068BF',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#fff', // '#f44336'
    contrastText: '#ffffff',
  },
  ...(mode === 'light'
    ? {
        background: {
          default: '#fafafa',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#5C5C5C',
        },
      }
    : {
        background: {
          default: '#303030',
          paper: '#424242',
        },
        text: {
          primary: '#ffffff',
        },
      }),
});

export const createLocalTheme = (theme: PaletteMode = 'dark') => {
  return createTheme(
    {
      palette: getDesignTokens(theme),
      typography: {
        fontFamily: ['Inter', 'Arial', 'apple-system', 'sans-serif'].join(','),
        h1: {
          fontSize: 48,
          fontWeight: 700,
          color: theme === 'dark' ? '#D8D8D8' : '#656565',
        },
        h2: {
          fontSize: 24,
          fontWeight: 500,
        },
        h3: {
          fontSize: 20,
          fontWeight: 500,
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 400,
        },
        body1: {
          fontSize: 16,
          fontWeight: 400,
        },
        body2: {
          fontSize: 14,
          fontWeight: 400,
        },
      },
      overrides: {
        MuiOutlinedInput: {
          root: {
            borderRadius: 20,
          },
        },
        MuiSelect: {
          outlined: {
            '&:focus': {
              borderRadius: 20,
            },
          },
        },
        MuiButton: {
          root: {
            borderRadius: 10,
          },
          containedSecondary: {
            border: `1px solid #F05050`,
            background: '#F05050 !important',
          },
          containedPrimary: {
            border: `1px solid #08BDF9`,
          },
        },
        MuiFormControl: {
          marginNormal: {
            margin: '0px !important',
          },
        },
        MuiTableRow: {
          root: {
            '&$selected': {
              backgroundColor: '#2068BF26',
            },
          },
        },
        MuiAccordion: {
          root: {
            border: '1px solid',
            borderColor: theme === 'dark' ? '#858585' : '#DBDBDB',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
            borderRadius: 20,
            '&:before': {
              display: 'none',
            },
          },
        },
        MuiCssBaseline: {
          '@global': {
            '*::-webkit-scrollbar': {
              width: '0.4em',
            },
            '*::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
            },
          },
        },
      },
    },
    ptBR,
  );
};
