/* eslint-disable import/no-duplicates */
import {
  format,
  formatDistance,
  formatDistanceStrict,
  parseISO,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function formatDate(date: string) {
  const parseDate = parseISO(date);
  if (parseDate.getTime() > 0) {
    return format(parseDate, 'dd/MM/yyyy');
  }
  return date;
}

export function createDateAsUTC(date: Date): Date {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );
}

export function formatDistanceDate(date1: Date, date2: Date) {
  const distance = parseInt(
    formatDistanceStrict(date1, date2, { unit: 'second' }),
    10,
  );

  const formatedDistance = formatDistance(0, distance * 1000, {
    includeSeconds: true,
    addSuffix: false,
    locale: ptBR,
  });

  return formatedDistance;
}
