import { Tooltip, Typography } from '@material-ui/core';
import {
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularConnectedNoInternet0Bar,
} from '@material-ui/icons';

import { useStyles } from './styles';

interface GsmSignalIconProps {
  signal: string;
  manufacturer: string;
  fontSize?: number;
}

interface SignalInfo {
  level: number;
  label: string;
}

function formatE3(value: string): SignalInfo {
  const numberValue = Number(value);

  if (numberValue > 20) {
    return { level: 4, label: `${numberValue}-32` }; // excellent
  }
  if (numberValue > 15) {
    return { level: 3, label: `${numberValue}-32` }; // good
  }
  if (numberValue > 10) {
    return { level: 2, label: `${numberValue}-32` }; // fair
  }
  if (numberValue > 2) {
    return { level: 1, label: `${numberValue}-32` }; // poor
  }
  return { level: 0, label: `${numberValue}-32` }; // no signal
}

function formatGT06(value: string): SignalInfo {
  const numberValue = Number(value);

  return { level: numberValue, label: `${numberValue}-4` };
}

function formatSuntech(value: string): SignalInfo {
  const numberValue = Number(value);

  if (numberValue >= -70) {
    return { level: 4, label: `${numberValue}Dbm` }; // "excellent"
  }
  if (numberValue >= -85) {
    return { level: 3, label: `${numberValue}Dbm` }; // "good"
  }
  if (numberValue >= -100) {
    return { level: 2, label: `${numberValue}Dbm` }; // "fair"
  }
  if (numberValue >= -110) {
    return { level: 1, label: `${numberValue}Dbm` }; // "poor"
  }
  return { level: 0, label: `${numberValue}Dbm` }; // "no signal"
}

export function GsmSignalIcon({
  signal,
  manufacturer,
  fontSize = 0,
}: GsmSignalIconProps) {
  const classes = useStyles();

  if (!signal || !manufacturer) {
    return <Typography variant="inherit">-</Typography>;
  }

  let signalInfo: SignalInfo | null = null;

  switch (manufacturer) {
    case 'BWS':
      signalInfo = formatE3(signal);
      break;
    case 'GT06':
    case 'HINOVA':
    case 'LOGYMETRIA':
    case 'X3TECH':
      signalInfo = formatGT06(signal);
      break;
    case 'SUNTECH':
      signalInfo = formatSuntech(signal);
      break;
    default:
      break;
  }

  if (signalInfo?.level === 0) {
    return (
      <Tooltip title={signalInfo.label} arrow placement="right">
        <SignalCellularConnectedNoInternet0Bar
          fontSize="small"
          className={classes.noSignal}
          style={{
            fontSize: fontSize || 'auto',
          }}
        />
      </Tooltip>
    );
  }

  if (signalInfo?.level === 1) {
    return (
      <Tooltip title={signalInfo.label} arrow placement="right">
        <SignalCellular1Bar
          fontSize="small"
          className={classes.lowSignal}
          style={{
            fontSize: fontSize || 'auto',
          }}
        />
      </Tooltip>
    );
  }

  if (signalInfo?.level === 2) {
    return (
      <Tooltip title={signalInfo.label} arrow placement="right">
        <SignalCellular2Bar
          fontSize="small"
          className={classes.highSignal}
          style={{
            fontSize: fontSize || 'auto',
          }}
        />
      </Tooltip>
    );
  }

  if (signalInfo?.level === 3) {
    return (
      <Tooltip title={signalInfo.label} arrow placement="right">
        <SignalCellular3Bar
          fontSize="small"
          className={classes.highSignal}
          style={{
            fontSize: fontSize || 'auto',
          }}
        />
      </Tooltip>
    );
  }

  if (signalInfo?.level === 4) {
    return (
      <Tooltip title={signalInfo.label} arrow placement="right">
        <SignalCellular4Bar
          fontSize="small"
          className={classes.highSignal}
          style={{
            fontSize: fontSize || 'auto',
          }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={signal} arrow placement="right">
      <Typography variant="inherit">-</Typography>
    </Tooltip>
  );

  // const renderIcon = useCallback(() => {
  //   if (signal === undefined) return <>-</>;
  //   if (Number(signal) === 0)
  //     return (
  //       <SignalCellularConnectedNoInternet0Bar
  //         fontSize="small"
  //         className={classes.noSignal}
  //         style={{
  //           fontSize: fontSize || 'auto',
  //         }}
  //       />
  //     );
  //   if (Number(signal) < 25)
  //     return (
  //       <SignalCellular1Bar
  //         fontSize="small"
  //         className={classes.lowSignal}
  //         style={{
  //           fontSize: fontSize || 'auto',
  //         }}
  //       />
  //     );
  //   if (Number(signal) < 50)
  //     return (
  //       <SignalCellular2Bar
  //         fontSize="small"
  //         className={classes.highSignal}
  //         style={{
  //           fontSize: fontSize || 'auto',
  //         }}
  //       />
  //     );
  //   if (Number(signal) < 75)
  //     return (
  //       <SignalCellular3Bar
  //         fontSize="small"
  //         className={classes.highSignal}
  //         style={{
  //           fontSize: fontSize || 'auto',
  //         }}
  //       />
  //     );
  //   return (
  //     <SignalCellular4Bar
  //       fontSize="small"
  //       className={classes.highSignal}
  //       style={{
  //         fontSize: fontSize || 'auto',
  //       }}
  //     />
  //   );
  // }, [signal, classes, fontSize]);

  // return (
  //   <Tooltip title={`${signal}%`} arrow placement="right">
  //     {renderIcon()}
  //   </Tooltip>
  // );
}
