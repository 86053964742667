import policeSiren from '../assets/sounds/police-siren.wav';

const notification = new Audio(policeSiren);
export async function playSound() {
  try {
    await notification.play();
  } catch (error) {
    console.log('can not play audio notification', error);
  }
}
