function formatCoord(coord: string) {
  if (!coord) return '-';
  return coord.substring(0, coord.indexOf('.') + 6);
}

interface LatLonFormatProps {
  latLng?: { lat: string; lng: string };
  coord?: string;
}

export function LatLonFormat({ latLng, coord }: LatLonFormatProps) {
  if (latLng === undefined && coord === undefined) return <>-</>;
  if (latLng) {
    return (
      <>
        {formatCoord(latLng.lat)}
        <br />
        {formatCoord(latLng.lng)}
      </>
    );
  }
  if (coord) {
    return <>{formatCoord(coord)}</>;
  }
  return <>-</>;
}
