import {
  Route as ReactDOMRoute,
  Redirect,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';
import { useAuth } from 'context/authProvider';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate: boolean;
  component: React.ComponentType<{ hasMenu: boolean }>;
  hasMenu?: boolean;
}

export function Route({
  isPrivate = false,
  component: Component,
  hasMenu = false,
  ...rest
}: RouteProps): JSX.Element {
  const { isAuthenticated } = useAuth();

  /**
   * ?Logica:
   * se o usuário estiver authenticado, tanto faz a rota na qual ele for entrar,
   * ja pode passar direto. Se ele não estiver autenticado e a rota for privada,
   * o mesmo é redirecionado para a tela de login. Outras verificações
   * como: rotas somente para mobile ou o usuário authenticado n poder entrar
   * na tela de login estão sendo feitas em seus respectivos componentes.
   * navigationProvider.tsx para verificar se a rota pode ser ser acessada pelo
   * mobile e no componente login(pages/Login/index.tsx) para impedir que um
   * usuário autenticado acesse a rota
   *
   * *OBS: nesses dois casos oque o ocorre é um redirecionamento
   * !AVISO:
   * essa verificação pode ter algumas falhas. caso existir em algum dia
   * níveis de contas, certos usuários terem acesso a somente certas páginas,
   * teriamos de rever essa lógica
   */

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) {
          return <Component hasMenu={hasMenu} />;
        }
        if (isPrivate) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }
        return <Component hasMenu={hasMenu} />;
      }}
    />
  );
}
