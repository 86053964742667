import { useQuery } from 'react-query';
import api from 'services/api';
import { IGeoFence } from '../types';

export async function getFences(companyId: number): Promise<IGeoFence[]> {
  const response = await api.get(`geofence/company/${companyId}`);
  return response.data.payload;
}

export const useGetFences = (companyId: number) => {
  return useQuery({
    queryKey: 'fences',
    queryFn: () => getFences(companyId),
    enabled: !!companyId,
    refetchOnWindowFocus: false,
  });
};
