import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './authProvider';
import { NavigationProvider } from './navigationProvider';
import { AppThemeProvider } from './themeProvider';
import { GridProvider } from './gridProvider.js';
import { MapProvider } from './mapProvider';
import { ToastProvider } from './toastProvider';
import { EventsProvider } from './eventsProvider';

export const queryClient = new QueryClient();

interface AppProviderProps {
  children: React.ReactNode;
}

export default function AppProvider({ children }: AppProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={5}>
        <ToastProvider>
          <AuthProvider>
            <EventsProvider>
              <AppThemeProvider>
                <NavigationProvider>
                  <MapProvider>
                    <GridProvider>{children}</GridProvider>
                  </MapProvider>
                </NavigationProvider>
              </AppThemeProvider>
            </EventsProvider>
          </AuthProvider>
        </ToastProvider>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} position="top-right" />
    </QueryClientProvider>
  );
}
