import { makeStyles, Theme } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
};
export const useStyles = makeStyles<Theme, StyleProps>({
  settings: {
    display: 'grid',
    placeContent: 'center',
    paddingBottom: ({ isMobile }) => (isMobile ? 0 : 12),
  },
});
