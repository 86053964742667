import { useCustomTheme } from 'context/themeProvider';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useStyles } from './style';
import { Headings } from './HeadingForMD';

export function VersionPage() {
  const { appTheme } = useCustomTheme();
  const classes = useStyles({ themeColor: appTheme });

  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('updates.md')
      .then(res => res.text())
      .then(text => setContent(text))
      // eslint-disable-next-line consistent-return
      .finally(() => {
        const url = window.location.href;
        const topic = url.split('#')[1];
        if (topic) {
          const timeout = setTimeout(() => {
            const element = document.getElementById(topic);
            element?.focus();
          }, 500);

          return () => clearTimeout(timeout);
        }
      });
  }, []);

  return (
    <div className={classes.globalStyle}>
      <ReactMarkdown
        components={{
          h1: Headings,
          h2: Headings,
          h3: Headings,
          h4: Headings,
          h5: Headings,
          h6: Headings,
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}
