/* eslint-disable prettier/prettier */
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Skeleton } from '@material-ui/lab';
import { minutesToHours } from 'date-fns';
import { useStyles } from './styles';

type SubInfosEventProps = {
  typeEvent?: string;
  plate?: string;
  dateTime?: string;
  model?: string;
  differenceInMinutes?: string;
};

const sanitizedHours = (differenceInMinutes: number) => {
  const difference = differenceInMinutes;
  if (differenceInMinutes > 60) {
    if (differenceInMinutes % 60 > 0) {
      return `${minutesToHours(differenceInMinutes)} Horas e ${difference % 60
        } minutos`;
    }
    return `${minutesToHours(differenceInMinutes)} Horas`;
  }
  return `${difference} Minutos`;
};

const renderContent = (content?: string) => {
  if (content) {
    return content
  }
  return <Skeleton variant="text" width={50} style={{
    background: '#00000073',
    display: 'inline-block',
  }} />
}

export function SubInfosEvents({
  typeEvent,
  plate,
  dateTime,
  model,
  differenceInMinutes,
}: SubInfosEventProps) {
  const classes = useStyles();
  return (
    <section className={classes.subInfoEventsContainer}>
      <section className={classes.subInfoContent}>
        <h6 className={classes.titleSubInfosCard}>
          <QueryBuilderIcon
            style={{
              color: '#2C9BE4',
              position: 'relative',
              top: '-2px',
            }}
          />
          {sanitizedHours(Number(renderContent(differenceInMinutes)))}
        </h6>
        <span className={classes.textEvents}>{renderContent(typeEvent)}</span>
      </section>
      <section>
        <section className={classes.sectionCardInfo}>
          <CreditCardIcon className={classes.icon} />
          <p className={classes.text}>Placa: {renderContent(plate)}</p>
        </section>
        <section className={classes.sectionCardInfo}>
          <DriveEtaIcon className={classes.icon} />
          <p className={classes.text}>Modelo: {renderContent(model)}</p>
        </section>
        <section className={classes.sectionCardInfo}>
          <QueryBuilderIcon className={classes.icon} />
          <p className={classes.text}>{renderContent(dateTime)}</p>
        </section>
      </section>
    </section>
  );
}
