import { ReactNode } from 'react';
import { LatLonFormat } from '../LatLngFormat';
import { useStyles } from './styles';

interface AddressGoogleMapsLinkProps {
  lat: string;
  lng: string;
  label?: string | ReactNode;
}

export function AddressGoogleMapsLink({
  lat,
  lng,
  label,
}: AddressGoogleMapsLinkProps) {
  const classes = useStyles();

  const googleUrl = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`;
  return (
    <a
      className={classes.link}
      // href={`http://maps.google.com/maps?q=${lat},${lng}&ll=${lat},${lng}&z=19`}
      href={googleUrl}
      target="_blank"
      rel="noreferrer"
    >
      {label ?? <LatLonFormat latLng={{ lat, lng }} />}
    </a>
  );
}
