import { useCan } from 'hooks/useCan';
import { ReactNode } from 'react';

interface CanProps {
  children: ReactNode;
  permissions?: string[];
  roles?: string[];
  allowedCompanies?: number[];
  blockedCompanies?: number[];
  // allowedUsers?: number[];
  // blockedUsers?: number[];
}

export function Can({
  children,
  permissions,
  roles,
  allowedCompanies,
  blockedCompanies,
}: // allowedUsers,
// blockedUsers,
CanProps) {
  const userCanSeeComponent = useCan({
    allowedCompanies,
    blockedCompanies,
    permissions,
    role: roles,
    // allowedUsers,
    // blockedUsers,
  });
  if (!userCanSeeComponent) {
    return null;
  }

  return <>{children}</>;
}
