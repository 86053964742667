import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    color: theme.palette.warning.main,
    verticalAlign: 'middle',
    fontSize: 15,
    marginLeft: 5,
  },
}));
