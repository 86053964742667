import { useAuth } from 'context/authProvider';
import { validateUserPermissions } from 'utils/validateUserPermissions';

type UseCanParams = {
  permissions?: string[];
  role?: string[];
  allowedCompanies?: number[];
  blockedCompanies?: number[];
  // allowedUsers?: number[];
  // blockedUsers?: number[];
};

export function useCan({
  permissions,
  role,
  allowedCompanies,
  blockedCompanies,
}: // allowedUsers,
// blockedUsers,
UseCanParams): boolean {
  const { user, isAuthenticated } = useAuth();
  // console.log('🚀 ~ user:', user);
  if (allowedCompanies && allowedCompanies.length) {
    if (!allowedCompanies.includes(user.companyId)) {
      return false;
    }
  }

  if (blockedCompanies && blockedCompanies.length) {
    if (blockedCompanies.includes(user.companyId)) {
      return false;
    }
  }
  // if (allowedUsers && allowedUsers.length) {
  //   if (!allowedUsers.includes(user.id)) {
  //     return false;
  //   }
  // }

  // if (blockedUsers && blockedUsers.length) {
  //   if (blockedUsers.includes(user.id)) {
  //     return false;
  //   }
  // }

  if (!isAuthenticated) {
    return false;
  }

  const userHasValidPermissions = validateUserPermissions({
    user,
    permissions,
    roles: role,
  });

  return userHasValidPermissions;
}
