/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */

import {
  Box,
  Card,
  Paper,
  CardHeader,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useGrid } from 'context/gridProvider.js';
import { useContext, useEffect } from 'react';
import { TDeviceInfo } from 'types/deviceInfo';
import { SiGooglemaps, SiWaze } from 'react-icons/si';
import {
  coordsCenterMap,
  dialogContext,
} from 'pages/SharedTracking/SharedTrackerMap';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';
import { VehicleInfoCard } from '../VehicleInfoCard';
import { FixedButton } from '../FixedButton';
import { EventTab } from '../EventScreen/EventTab';
import { SubInfosCard } from '../SubInfosCard';
import { TimerContainer } from '../TimerContainer';

export type CardInfoProps = {
  deviceId?: string;
  deviceData?: any;
  onClick?: () => void;
  handlePolyline?: () => void;
  handleActiveTrace?: () => void;
  lat?: string;
  lng?: string;
  handleInvalidateToken: () => void;
  expiresIn: number;
  fullShare: boolean;
};

export function CardCarInfo({
  deviceId,
  deviceData,
  handleActiveTrace,
  lat,
  lng,
  onClick,
  expiresIn,
  handleInvalidateToken,
  fullShare,
}: CardInfoProps) {
  const { dialogIsOpen, setDialogIsOpen } = useContext(dialogContext);
  const { currentMapCenter } = useContext(coordsCenterMap);
  const classes = useStyles({ fullShare });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(450));
  const { fetchDeviceInfoByImei, state } = useGrid();
  const { fetchedDeviceInfo } = state;
  const { vehicle } = (fetchedDeviceInfo as TDeviceInfo) || {};
  const handleChange = () => {
    if (setDialogIsOpen) setDialogIsOpen(prev => !prev);
  };
  useEffect(() => {
    if (deviceId) fetchDeviceInfoByImei(deviceId);
  }, [deviceId, fetchDeviceInfoByImei]);
  const carHasCenter = () => {
    return !(
      currentMapCenter?.lat.toFixed(7) === lat &&
      currentMapCenter?.lng.toFixed(7) === lng
    );
  };
  const traceCarIcon = (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 18C16.35 18 15.7667 17.8127 15.25 17.438C14.7333 17.0627 14.375 16.5833 14.175 16H9C7.9 16 6.95833 15.6083 6.175 14.825C5.39167 14.0417 5 13.1 5 12C5 10.9 5.39167 9.95833 6.175 9.175C6.95833 8.39167 7.9 8 9 8H11C11.55 8 12.021 7.804 12.413 7.412C12.8043 7.02067 13 6.55 13 6C13 5.45 12.8043 4.979 12.413 4.587C12.021 4.19567 11.55 4 11 4H5.825C5.60833 4.58333 5.246 5.06267 4.738 5.438C4.22933 5.81267 3.65 6 3 6C2.16667 6 1.45833 5.70833 0.875 5.125C0.291667 4.54167 0 3.83333 0 3C0 2.16667 0.291667 1.45833 0.875 0.875C1.45833 0.291667 2.16667 0 3 0C3.65 0 4.22933 0.187333 4.738 0.562C5.246 0.937333 5.60833 1.41667 5.825 2H11C12.1 2 13.0417 2.39167 13.825 3.175C14.6083 3.95833 15 4.9 15 6C15 7.1 14.6083 8.04167 13.825 8.825C13.0417 9.60833 12.1 10 11 10H9C8.45 10 7.97933 10.1957 7.588 10.587C7.196 10.979 7 11.45 7 12C7 12.55 7.196 13.021 7.588 13.413C7.97933 13.8043 8.45 14 9 14H14.175C14.3917 13.4167 14.7543 12.9373 15.263 12.562C15.771 12.1873 16.35 12 17 12C17.8333 12 18.5417 12.2917 19.125 12.875C19.7083 13.4583 20 14.1667 20 15C20 15.8333 19.7083 16.5417 19.125 17.125C18.5417 17.7083 17.8333 18 17 18ZM3 4C3.28333 4 3.521 3.90433 3.713 3.713C3.90433 3.521 4 3.28333 4 3C4 2.71667 3.90433 2.479 3.713 2.287C3.521 2.09567 3.28333 2 3 2C2.71667 2 2.479 2.09567 2.287 2.287C2.09567 2.479 2 2.71667 2 3C2 3.28333 2.09567 3.521 2.287 3.713C2.479 3.90433 2.71667 4 3 4Z"
        fill="white"
      />
    </svg>
  );
  if (!setDialogIsOpen) return null;
  return (
    <Box className={classes.root}>
      <Card
        className={`${classes.cardInfoContent} ${
          dialogIsOpen ? '' : classes.hideCard
        }`}
      >
        <CardHeader
          className={classes.cardHeaderContent}
          title={
            <>
              <Box>
                {!dialogIsOpen ? (
                  <>
                    <section className={classes.containerButtonPlate}>
                      <section>
                        {fullShare ? (
                          <EventTab carInfo={vehicle} imei={String(deviceId)} />
                        ) : null}
                      </section>
                      <Box
                        component="button"
                        onClick={handleChange}
                        className={classes.buttonMobile}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.plateStyle}
                        >
                          PLACA: {vehicle?.plate}
                        </Typography>
                        <SubInfosCard cardInfo={deviceData} />
                      </Box>
                      <div className={classes.hideButtonClose}>
                        <button
                          type="button"
                          style={{
                            border: 'none',
                            background: 'none',
                            width: '40px',
                          }}
                          onClick={handleChange}
                        >
                          <ExpandLessIcon
                            style={{
                              display: 'grid',
                              fontSize: '36px',
                              position: 'relative',
                              color: '#2A99D7',
                            }}
                          />
                        </button>
                      </div>
                    </section>
                  </>
                ) : (
                  <>
                    <section
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <section>
                        <p
                          style={{
                            color: '#676767',
                            fontSize: '10px',
                            fontWeight: 500,
                            width: 65,
                            position: 'relative',
                            top: 0,
                            left: 2,
                          }}
                        >
                          <TimerContainer
                            expiresIn={expiresIn}
                            handleInvalidateToken={handleInvalidateToken}
                            fullShare={fullShare}
                          />
                        </p>
                      </section>
                      <Box
                        component="button"
                        onClick={handleChange}
                        className={classes.buttonMobile}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            textAlign: 'center',
                            fontSize: 14,
                            color: '#4f4e4e',
                          }}
                        >
                          PLACA: {vehicle?.plate}
                        </Typography>
                      </Box>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <button
                          type="button"
                          onClick={handleChange}
                          style={{ border: 'none', background: 'none' }}
                        >
                          <ExpandMoreIcon
                            style={{
                              display: 'grid',
                              fontSize: '36px',
                              position: 'relative',
                              color: '#2A99D7',
                            }}
                          />
                        </button>
                      </div>
                    </section>
                  </>
                )}
              </Box>
            </>
          }
        />
        <Paper style={{ display: 'contents' }}>
          <VehicleInfoCard lastPosition={deviceData} vehicle={vehicle} />
          <Box component="section" className={classes.vehicleInfoCardContainer}>
            {matches && carHasCenter() ? (
              <section className={classes.fixedButtonContainer}>
                {onClick && <FixedButton onClick={onClick} />}
              </section>
            ) : null}
            {fullShare ? (
              <EventTab carInfo={vehicle} imei={String(deviceId)} />
            ) : null}
            <a
              href={`https://www.waze.com/ul?ll=${lat},${lng}&navigate=yes&zoom=17`}
              target="_blank"
              rel="noreferrer"
              className={classes.ButtonStyles}
            >
              <SiWaze />
            </a>
            <a
              href={`http://maps.google.com/maps?daddr=${lat},${lng}&amp;navigate=yes`}
              target="_blank"
              rel="noreferrer"
              className={classes.ButtonStyles}
            >
              <SiGooglemaps />
            </a>
            {handleActiveTrace && (
              <Tooltip title="Traçado do veículo">
                <button
                  type="button"
                  className={classes.ButtonStyles}
                  onClick={() => {
                    handleActiveTrace();
                    handleChange();
                  }}
                >
                  {traceCarIcon}
                </button>
              </Tooltip>
            )}
          </Box>
        </Paper>
      </Card>
    </Box>
  );
}
