import { TPosition } from 'types/position';
import {
  Box,
  colors,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import { useContext } from 'react';
import { dialogContext } from 'pages/SharedTracking/SharedTrackerMap';

type MarckerProps = {
  lat?: number | string;
  lng?: number | string;
  device: any;
  position: TPosition;
  ignition: number;
  direction: number;
  speed: number;
};

type IconProps = {
  ignition: boolean;
  moving: boolean;
  direction: number;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      all: 'unset',
      cursor: 'pointer',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-5px',
      zIndex: 20,
      width: 20,
      height: 20,
      transform: 'translate(-50%, -50%)',
    },
    ignition: {
      all: 'unset',
      width: 20,
      height: 20,
      border: '2px solid white',
      borderRadius: '50%',
      zIndex: 20,
      cursor: 'pointer',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      '&:hover': {
        width: 30,
        height: 30,
      },
    },
    ignitionOnAndStopped: {
      backgroundColor: 'rgb(255,255,0)',
    },
    ignitionOff: {
      backgroundColor: 'rgb(255,0,0)',
    },
  }),
);

function RenderIcon({ direction, ignition, moving }: IconProps) {
  const classes = useStyles({});
  if (moving) {
    return (
      <Box className={classes.root}>
        <NavigationIcon
          style={{
            transform: `rotate(${direction}deg)`,
            fontSize: 30,
            color: colors.green[500],
          }}
        />
      </Box>
    );
  }
  if (ignition) {
    return (
      <Box className={`${classes.ignition} ${classes.ignitionOnAndStopped}`} />
    );
  }
  return <Box className={`${classes.ignition} ${classes.ignitionOff}`} />;
}

export function Marker({ position, speed, ignition }: MarckerProps) {
  const { switch: switchDialog } = useContext(dialogContext);
  const ignitionOn = ignition === 1;
  const isMoving = speed > 0;
  return (
    <Box onClick={switchDialog}>
      <RenderIcon
        moving={isMoving}
        ignition={ignitionOn}
        direction={Number(position?.direction)}
      />
    </Box>
  );
}
