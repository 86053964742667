export const defaultMapProps = {
  defaultZoom: 15,
  defaultCenter: { lat: -22.933825, lng: -43.6854926 },
  zoom: 15,
  center: undefined,
  options: {
    maxZoom: 20,
    fullscreenControlOptions: { position: 12 },
  },
};
