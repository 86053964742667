import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const createThemeDark = createTheme(
  {
    palette: {
      type: 'dark',
      secondary: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
    },
    typography: {
      input: {
        fontSize: '0.7rem',
        labelFontSize: '0.7rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em',
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        },
      },
    },
  },
  ptBR,
);

const createThemeLight = createTheme(
  {
    palette: {
      type: 'light',
      secondary: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
    },
    typography: {
      input: {
        fontSize: '0.7rem',
        labelFontSize: '0.7rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em',
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        },
      },
    },
  },
  ptBR,
);

const light = responsiveFontSizes(createThemeLight);
const dark = responsiveFontSizes(createThemeDark);

export { light, dark };
