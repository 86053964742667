import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider } from '@material-ui/core/styles';

import { storage } from 'utils';
import { light, dark } from '../styles/theme.js';
import { useAuth } from './authProvider';

interface ThemeContextProps {
  appTheme: 'light' | 'dark';
  setTheme: Dispatch<SetStateAction<string>>;
}

const themes = {
  light,
  dark,
};

function getTheme(theme: 'light' | 'dark') {
  return themes[theme];
}

const ThemeContext = createContext({} as ThemeContextProps);

const AppThemeProvider = ({ children }: { children: ReactNode }) => {
  const { updateUserSettings, user } = useAuth();
  const [appTheme, setAppTheme] = useState<'dark' | 'light'>(() => {
    const settings = storage.getItem({
      key: 'settings',
      storageType: 'session',
    });

    return settings?.theme ?? 'light';
  });

  useEffect(() => {
    if (user.userSettings?.theme) setAppTheme(user.userSettings?.theme);
  }, [user.userSettings?.theme]);

  const theme = getTheme(appTheme);

  const setTheme = useCallback(
    value => {
      setAppTheme(value);
      updateUserSettings({ theme: value });
    },
    [updateUserSettings],
  );

  return (
    <ThemeContext.Provider value={{ appTheme, setTheme }}>
      <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

function useCustomTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within an AuthPovider');
  }

  return context;
}

export { AppThemeProvider, useCustomTheme };
