import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import { PositionsService } from 'services/positions';
import { TPosition } from 'types/position';
import { PinDrop } from '@material-ui/icons';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PaletteIcon from '@material-ui/icons/Palette';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SpeedIcon from '@material-ui/icons/Speed';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import { useStyles } from './styles';
import { TVehicle } from '../../../../../types/vehicle';

type VehicleInfoProps = {
  vehicle: TVehicle;
  lastPosition: TPosition;
};

export function VehicleInfoCard({ vehicle, lastPosition }: VehicleInfoProps) {
  const classes = useStyles();
  const [address, setAddress] = useState<string | undefined>();
  async function getAddress({ lat, lng }: { lat: string; lng: string }) {
    const resp = await PositionsService.positionToAddress({
      latitude: lat,
      longitude: lng,
    });
    setAddress(resp.data.payload.address);
  }
  useEffect(() => {
    if (lastPosition) {
      if (lastPosition?.address) {
        setAddress(lastPosition.address);
      } else {
        getAddress({ lat: lastPosition.latitude, lng: lastPosition.longitude });
      }
    }
  }, [lastPosition]);
  if (vehicle) {
    return (
      <Box
        style={{
          all: 'unset',
          display: 'grid',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          padding: '8px',
        }}
      >
        <section className={classes.bodyItem}>
          <p>
            <DriveEtaIcon className={classes.iconTexts} />
            Marca: {vehicle.make ?? <Skeleton variant="text" width={320} />}
          </p>

          <p>
            <PaletteIcon className={classes.iconTexts} />
            Cor: {vehicle.color ?? <Skeleton variant="text" width={320} />}
          </p>

          <p>
            <DriveEtaIcon className={classes.iconTexts} />
            Modelo: {vehicle.model ?? <Skeleton variant="text" width={320} />}
          </p>

          <p>
            <DateRangeIcon className={classes.iconTexts} />
            Ano:{' '}
            {vehicle.manufacturedYear ?? (
              <Skeleton variant="text" width={320} />
            )}{' '}
            / {vehicle.modelYear ?? <Skeleton variant="text" width={320} />}
          </p>

          <p>
            <AccountCircleIcon className={classes.iconTexts} />
            Motorista:{' '}
            {vehicle.driver ?? <Skeleton variant="text" width={320} />}
          </p>
          <p
            style={{
              lineHeight: '120%',
              color: '#4f4e4e',
              marginTop: '5px',
              display: 'flex',
              alignItems: 'center',
              fontSize: 10,
              gap: 5,
            }}
          >
            <PinDrop style={{ color: '#4f4e4e', fontSize: 20 }} />
            {address ?? <Skeleton variant="text" width={320} />}
          </p>
          <div style={{ border: '0.5px solid rgba(217, 217, 217, 0.5)' }} />
          <p>
            <VpnKeyIcon className={classes.iconTexts} />
            Ignição: {lastPosition.ignition ? 'Ligado' : 'Desligado'}
          </p>
          <p>
            <QueryBuilderIcon className={classes.iconTexts} />
            Data GPS: {lastPosition.dateTime}
          </p>

          <p>
            <SpeedIcon className={classes.iconTexts} />
            Velocidade: {lastPosition.speed}Km/h
          </p>

          <p>
            <FlashOnIcon className={classes.iconTexts} />
            Tensão: {lastPosition.voltage}v
          </p>

          <p>
            <SignalCellularAltIcon className={classes.iconTexts} />
            Sinal: {lastPosition.signal}%
          </p>
        </section>
      </Box>
    );
  }
  return null;
}
