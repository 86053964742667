/* eslint-disable no-plusplus */
import { useState, useEffect, ComponentType, useCallback } from 'react';
import packageJson from '../../../package.json';

function checkOutdatedVersion({
  currentVersion,
  latestVersion,
}: {
  latestVersion: string;
  currentVersion: string;
}) {
  const currentVersionNumber = currentVersion.split('.').map(Number);
  const latestVersionNumber = latestVersion.split('.').map(Number);

  for (
    let i = 0;
    i < Math.max(currentVersionNumber.length, latestVersionNumber.length);
    i++
  ) {
    const currentVersionNumberPart = currentVersionNumber[i] || 0;
    const latestVersionNumberPart = latestVersionNumber[i] || 0;
    if (latestVersionNumberPart > currentVersionNumberPart) {
      return true;
    }
  }

  return false;
}

function withClearCache(Component: ComponentType) {
  function ClearCacheComponent(props: any): JSX.Element {
    const [isVersionUpToDate, setIsVersionUpToDate] = useState(true);

    const refreshCacheAndReload = useCallback(async () => {
      if ('caches' in window) {
        const cacheKeys = await caches.keys();
        console.log('cleaning caches', cacheKeys);
        await Promise.all(cacheKeys.map(cacheKey => caches.delete(cacheKey)));
        console.log('cache cleaned');
      }
      window.location.reload();
    }, []);

    useEffect(() => {
      fetch('/meta.json', { cache: 'no-cache' })
        .then(response => response.json())
        .then(meta => {
          const metaLatestVersion = meta.version;
          const metaCurrentVersionDate = packageJson.version;

          const isOutdated = checkOutdatedVersion({
            currentVersion: metaCurrentVersionDate,
            latestVersion: metaLatestVersion,
          });

          console.log({
            metaLatestVersion,
            metaCurrentVersionDate,
            isOutdated,
          });

          if (isOutdated) {
            console.log('app version is outdated');
            setIsVersionUpToDate(false);
            refreshCacheAndReload();
          } else {
            setIsVersionUpToDate(true);
          }
        })
        .catch(error => {
          console.log('error fetching meta.json', error);
          setIsVersionUpToDate(true);
        });
    }, [refreshCacheAndReload]);

    return <>{isVersionUpToDate ? <Component {...props} /> : null}</>;
  }

  return ClearCacheComponent;
}

export default withClearCache;
