import { TraceData } from 'types/position';

interface stateProps {
  modalSelectedRow: [];
  devicesInGrid: [];
  deviceIdTrace: null | string;
  traceOnGridMap: TraceData[];
  fetchedDevices: [];
  fetchedDeviceInfo: null | any;
  storagedDevices: [];
  gridSelectedRow: {};
  selectedDeviceId: null | string;
  selectedDeviceImei: null | string;
  selectedDeviceToHandle: null | any;
  selectedDeviceToHandle2: any;
  selectedRowInGrid: null | any;
  selectedEventType: [];
  searchModalOpen: boolean;
  deviceHandlingModalOpen: boolean;
  loadingDeviceSearch: boolean;
  loadingDeviceInfo: boolean;
  mapDefaults: {
    zoom: number;
    center: [number, number];
  };
  currentZoom: null | number;
  currentDeviceCoords: null | number;
  infoWindowIndex: null | number;
  selectedModalTabIndex: 0;
  temporaryInGrid: { [key: string]: any };
  eventGridSettings: [];
}

export const ACTIONS = {
  INSERT_DEVICES_IN_GRID: 'insertDevicesInGrid',
  REMOVE_DEVICES_FROM_GRID: 'removeDevicesFromGrid',
  CLEAR_GRID: 'clearGrid',
  SELECT_GRID_DEVICE_ROW: 'selectGridDevicesRow',
  SELECT_DEVICE_ROW_IN_GRID: 'seletDeviceRowInGrid',
  SET_SELECTED_IMEI: 'setSelectedImei',
  TOGGLE_SEARCH_MODAL: 'toggleSearchModal',
  OPEN_INFOWINDOW: 'openInfoWindow',
  CLOSE_INFOWINDOW: 'closeInfoWindow',
  TOGGLE_DEVICE_HANDLING_MODAL: 'toggleDeviceHandlingModal',
  CLOSE_DEVICE_HANDLING_MODAL: 'closeDeviceHandlingModal',
  FETCH_DEVICES: 'fetchedDevices',
  SUCCESS_FETCHED_DEVICES: 'successFechedDevices',
  ERROR_FETCHED_DEVICES: 'success',
  SELECT_DEVICE_TO_HANDLE: 'selectDeviceToHandle',
  CHANGE_SELECTED_MODAL_TAB_INDEX: 'changeSelectedModalTabIndex',
  CHANGE_SELECTED_GRID_TAB_INDEX: 'changeSelectedGridTabIndex',
  INSERT_STORAGED_DEVICES: 'insertStoragedDevices',
  REMOVE_STORAGED_DEVICES: 'removeStoragedDevices',
  FETCH_DEVICE_INFO: 'fetchedDeviceInfo',
  SUCCESS_FETCHED_DEVICE_INFO: 'successFetchedDeviceInfo',
  ERROR_FETCHED_DEVICE_INFO: 'errorFetchedDeviceInfo',
  CHANGE_SELECTED_EVENT_TYPE: 'changeSelectedEventType',
  CHANGE_EVENT_GRID_SETTINGS: 'changeEventGridSettings',
  ADD_FETCHED_TRACE_DATA: 'addFetchedTraceData',
  SELECT_MODAL_DEVICE_ROW: 'selectModalDeviceRow',
};

export const initialState = {
  modalSelectedRow: [],
  devicesInGrid: [],
  deviceIdTrace: null,
  traceOnGridMap: [],
  fetchedDevices: [],
  fetchedDeviceInfo: null,
  storagedDevices: [],
  gridSelectedRow: {},
  selectedDeviceId: null,
  selectedDeviceImei: null,
  selectedDeviceToHandle: null,
  selectedDeviceToHandle2: {},
  selectedRowInGrid: null,
  selectedEventType: [],
  searchModalOpen: false,
  deviceHandlingModalOpen: false,
  loadingDeviceSearch: false,
  loadingDeviceInfo: false,
  mapDefaults: {
    zoom: 4,
    center: [-11.20975103579961, -51.12938576657034],
  },
  currentZoom: null,
  currentDeviceCoords: null,
  infoWindowIndex: null,
  selectedModalTabIndex: 0,
  selectedGridTabIndex: 1,
  temporaryInGrid: {},
  eventGridSettings: [],
};

export const reducer = (state: stateProps, action: any) => {
  switch (action.type) {
    case ACTIONS.INSERT_DEVICES_IN_GRID:
      return {
        ...state,
        devicesInGrid: action.devicesInGrid,
      };

    case ACTIONS.CLEAR_GRID:
      return {
        ...state,
        devicesInGrid: initialState.devicesInGrid,
        selectedRowInGrid: null,
      };

    case ACTIONS.REMOVE_DEVICES_FROM_GRID:
      return {
        ...state,
        devicesInGrid: action.devicesInGrid,
      };

    case ACTIONS.SELECT_MODAL_DEVICE_ROW:
      return {
        ...state,
        modalSelectedRow: action.modalSelectedRow,
      };

    case ACTIONS.SELECT_GRID_DEVICE_ROW:
      return {
        ...state,
        gridSelectedRow: action.gridSelectedRow,
        currentZoom: 15,
        currentDeviceCoords: action.currentDeviceCoords,
      };

    case ACTIONS.SELECT_DEVICE_ROW_IN_GRID:
      return {
        ...state,
        infoWindowIndex: null,
        selectedRowInGrid: action.selectedRowInGrid,
        selectedDeviceId: action.deviceId,
        selectedDeviceImei: action.imei,
        deviceIdTrace: action.deviceIdTrace,
        traceOnGridMap:
          action.deviceIdTrace !== state.deviceIdTrace
            ? []
            : action.traceOnGridMap,
        selectedEventType: action.eventSlug ? [action.eventSlug] : [],
        temporaryInGrid: action.temporaryInGrid,
      };

    case ACTIONS.SET_SELECTED_IMEI:
      return {
        ...state,
        selectedDeviceImei: action.imei,
        deviceIdTrace: null,
        infoWindowIndex: null,
        selectedDeviceId: null,
        selectedEventType: [],
        selectedRowInGrid: null,
        temporaryInGrid: {},
        traceOnGridMap: [],
      };

    case ACTIONS.TOGGLE_SEARCH_MODAL:
      return {
        ...state,
        searchModalOpen: !state.searchModalOpen,
        fetchedDevices: [],
      };

    case ACTIONS.OPEN_INFOWINDOW:
      return {
        ...state,
        infoWindowIndex: action.infoWindowIndex,
      };

    case ACTIONS.CLOSE_INFOWINDOW:
      return {
        ...state,
        infoWindowIndex: null,
      };

    case ACTIONS.FETCH_DEVICES:
      return {
        ...state,
        loadingDeviceSearch: true,
      };
    case ACTIONS.FETCH_DEVICE_INFO:
      if (action.imei === state.fetchedDeviceInfo?.device?.imei) {
        return {
          ...state,
          loadingDeviceInfo: true,
        };
      }

      return {
        ...state,
        loadingDeviceInfo: true,
        fetchedDeviceInfo: {},
      };

    case ACTIONS.SUCCESS_FETCHED_DEVICES:
      return {
        ...state,
        fetchedDevices: action.fetchedDevices,
        loadingDeviceSearch: false,
      };

    case ACTIONS.SUCCESS_FETCHED_DEVICE_INFO:
      return {
        ...state,
        fetchedDeviceInfo: action.fetchedDeviceInfo,
        loadingDeviceInfo: false,
      };

    case ACTIONS.ERROR_FETCHED_DEVICES:
      return {
        ...state,
        loadingDeviceSearch: false,
        fetchedDevices: [],
      };
    case ACTIONS.ERROR_FETCHED_DEVICE_INFO:
      return {
        ...state,
        loadingDeviceInfo: false,
        fetchedDeviceInfo: [],
      };

    case ACTIONS.TOGGLE_DEVICE_HANDLING_MODAL:
      return {
        ...state,
        deviceHandlingModalOpen: !state.deviceHandlingModalOpen,
        // fetchedDeviceInfo: null,
        selectedModalTabIndex: action.tabIndex,
      };

    case ACTIONS.CLOSE_DEVICE_HANDLING_MODAL:
      return {
        ...state,
        deviceHandlingModalOpen: false,
      };

    case ACTIONS.SELECT_DEVICE_TO_HANDLE:
      return {
        ...state,
        selectedDeviceToHandle: action.selectedDeviceToHandle,
        deviceHandlingModalOpen: !!state.gridSelectedRow,
      };

    case ACTIONS.CHANGE_SELECTED_MODAL_TAB_INDEX:
      return {
        ...state,
        selectedModalTabIndex: action.selectedModalTabIndex,
      };

    case ACTIONS.CHANGE_SELECTED_GRID_TAB_INDEX:
      return {
        ...state,
        selectedGridTabIndex: action.selectedGridTabIndex,
        temporaryInGrid: {},
      };

    case ACTIONS.CHANGE_SELECTED_EVENT_TYPE:
      return {
        ...state,
        selectedEventType: action.newSelectedEventType,
      };

    case ACTIONS.INSERT_STORAGED_DEVICES:
      return {
        ...state,
        storagedDevices: action.storagedDevices,
      };

    case ACTIONS.CHANGE_EVENT_GRID_SETTINGS:
      return {
        ...state,
        eventGridSettings: action.eventGridSettings,
      };

    case ACTIONS.ADD_FETCHED_TRACE_DATA:
      return {
        ...state,
        traceOnGridMap: action.traceOnGridMap,
      };

    default:
      throw Error(`Unhandled action: ${action.type}`);
  }
};
