/* eslint-disable jsx-a11y/control-has-associated-label */

import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { useStyles } from './styles';

type FixedButtonProps = {
  onClick: () => void;
};

export function FixedButton({ onClick }: FixedButtonProps) {
  const classes = useStyles();
  return (
    <button
      type="button"
      className={classes.fixedButtonStyles}
      onClick={onClick}
    >
      <GpsFixedIcon />
    </button>
  );
}
