/* eslint-disable prettier/prettier */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MapsRef, SharedMap } from 'pages/SharedTracking/components/Map';
import googleMapReact from 'google-map-react';
import { IDecodedToken } from 'services/sharedTracker';
import { useLocation } from '../../hooks/useLocation';
import { Header } from './components/Header';
import { Marker } from './components/Markers/Marker';
import { useStyles } from './styles/globalShareTracker';
import { PersonMarker } from './components/Markers/PersonMarker';
import { useCarInfo } from './utils/hooks/useCarInfo';
import { useCarPolyline } from './components/Map/useTraceCarPolyline';

type ISharedTrackMapProps = {
  decodedToken: IDecodedToken;
  handleInvalidateToken: () => void;
};

type Settedby = 'user' | 'action';

export const dialogContext = createContext<{
  switch?: () => void;
  dialogIsOpen?: boolean;
  setDialogIsOpen?: Dispatch<SetStateAction<boolean>>;
}>({});

export const coordsCenterMap = createContext<{
  currentMapCenter?: googleMapReact.Coords;
  setCurrentMapCenter?: (coords: googleMapReact.Coords) => void;
  changeSet?: (active: Settedby) => void;
  settedBy?: Settedby;
}>({});

export function SharedTrackMap({
  decodedToken,
  handleInvalidateToken,
}: ISharedTrackMapProps) {
  const classes = useStyles();
  const mapsRef = useRef<MapsRef>(null);
  const [settedby, setSettedby] = useState<Settedby>('action');
  const { polylineCarRef, createTraceCarPolyline } = useCarPolyline({
    mapElement: mapsRef.current?.mapElement,
    mapsInstance: mapsRef.current?.maps,
  });
  const { coords } = useLocation();
  const [currentMapCenter, setCurrentMapCenter] =
    useState<googleMapReact.Coords>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [activeTraceCar, setActiveTraceCar] = useState(false);
  const handleActiveTraceCar = () => {
    setActiveTraceCar(activeTraceCar => !activeTraceCar);
  };
  const { carInfo: data, traceCar } = useCarInfo({
    decodedToken,
    onSuccess(data) {
      if (settedby === 'action') {
        setTimeout(() => {
          mapsRef.current?.setCenter({
            lat: Number(data?.latitude),
            lng: Number(data?.longitude),
          });
        }, 1000);
      }
    },
  });
  useEffect(() => {
    if (traceCar) {
      createTraceCarPolyline(traceCar);
      polylineCarRef.current.polyline?.setVisible(activeTraceCar);
    }
  }, [activeTraceCar, createTraceCarPolyline, polylineCarRef, traceCar]);

  const centerMap = useCallback(() => {
    mapsRef.current?.setCenter({
      lat: Number(data?.latitude),
      lng: Number(data?.longitude),
    });
    mapsRef.current?.mapElement?.setZoom(15);
    setSettedby('action');
  }, [data?.latitude, data?.longitude]);

  const marker = useMemo(
    () =>
      data
        ? [
          <Marker
            position={data}
            lat={data.latitude}
            lng={data.longitude}
            direction={Number(data.direction)}
            speed={Number(data.speed)}
            ignition={data.ignition}
            device={data}
          />,
          !coords ? null : <PersonMarker lat={coords.lat} lng={coords.lng} />,
        ]
        : undefined,
    [coords, data],
  );
  if (!data) return null;
  return (
    <div className={classes.root}>
      <dialogContext.Provider
        value={{
          switch: () => setDialogIsOpen(oldValue => !oldValue),
          dialogIsOpen,
          setDialogIsOpen,
        }}
      >
        <coordsCenterMap.Provider
          value={{
            currentMapCenter,
            settedBy: settedby,
            changeSet(isActive) {
              setSettedby(isActive);
            },
            setCurrentMapCenter: coords => setCurrentMapCenter(coords),
          }}
        >
          <SharedMap ref={mapsRef}>{marker && marker}</SharedMap>

          <Header
            fullShare={decodedToken.fullShare}
            deviceData={data}
            deviceId={data.deviceId}
            activePolylineCar={activeTraceCar}
            deviceInfo={data}
            onClick={centerMap}
            expiresIn={decodedToken?.exp}
            plate={decodedToken?.plate}
            handleInvalidateToken={handleInvalidateToken}
            lat={data?.latitude}
            lng={data?.longitude}
            handleActiveTrace={handleActiveTraceCar}
          />

        </coordsCenterMap.Provider>
      </dialogContext.Provider>
    </div>
  );
}
