import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';

export function DateTimeFormat({ date, dateOnly = false }) {
  if (!date) return <>-</>;
  const formatedDate = format(
    new Date(date.replace(' ', 'T')),
    dateOnly ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm:ss',
    {
      locale: ptBR,
    },
  );
  return <>{formatedDate}</>;
}
