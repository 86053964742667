import GoogleMapReact from 'google-map-react';
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import { REACT_APP_GOOGLE_API_KEY } from 'config';
import { coordsCenterMap } from 'pages/SharedTracking/SharedTrackerMap';
import { defaultMapProps } from './config';

type Props = {
  children: React.ReactNode;
};

interface IMapProps {
  defaultCenter: GoogleMapReact.Coords | undefined;
  center: GoogleMapReact.Coords | undefined;
  defaultZoom: number | undefined;
  zoom: number | undefined;
  options: GoogleMapReact.MapOptions | undefined;
}

type Coords = { lat: number; lng: number };
export type MapsRef = {
  setCenter: (coords: Coords) => void;
  maps?: typeof google.maps;
  mapElement?: google.maps.Map;
  currentCoords: Coords;
};

export const SharedMap = forwardRef<MapsRef, Props>(function Maps(
  { children },
  ref,
) {
  const { setCurrentMapCenter, changeSet } = useContext(coordsCenterMap);
  const [mapProps] = useState<IMapProps>(defaultMapProps);
  const [currentCoords, setCurrentCoords] = useState<Coords>(
    defaultMapProps.defaultCenter,
  );
  const [maps, setMaps] = useState<typeof google.maps>();
  const [mapElement, setMapElement] = useState<google.maps.Map>();

  useImperativeHandle(ref, () => ({
    maps,
    setCenter(coords) {
      setCurrentCoords(coords);
    },
    currentCoords,
    mapElement,
  }));

  useEffect(() => {
    let layer: HTMLDivElement;
    let internalMapContainer: Element | null;
    if (maps) {
      internalMapContainer = document.querySelector('.gm-style');
      layer = document.createElement('div');
      layer.id = 'infoboxlayer';
      internalMapContainer?.append(layer);
    }
    return () => {
      if (internalMapContainer) {
        internalMapContainer.removeChild(layer);
      }
    };
  }, [maps]);
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: REACT_APP_GOOGLE_API_KEY }}
      defaultZoom={mapProps.zoom}
      defaultCenter={mapProps.center}
      zoom={mapProps.zoom}
      center={currentCoords}
      resetBoundsOnResize
      options={{
        fullscreenControl: false,
        zoomControl: false,
        gestureHandling: 'greedy',
      }}
      onGoogleApiLoaded={({ maps, map }) => {
        setMaps(maps);
        setMapElement(map);
      }}
      yesIWantToUseGoogleMapApiInternals
      onChange={e => {
        setCurrentCoords({
          lat: e.center.lat,
          lng: e.center.lng,
        });
        if (setCurrentMapCenter)
          setCurrentMapCenter({
            lat: e.center.lat,
            lng: e.center.lng,
          });
      }}
      onDrag={() => {
        if (changeSet) changeSet('user');
      }}
    >
      {children}
    </GoogleMapReact>
  );
});
