import { useEffect, useState } from 'react';

export function useLocation() {
  const [isUsable, setIsUsable] = useState(false);
  const [coords, setCoords] = useState<{ lat: number; lng: number }>();

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      ({ coords }) => {
        if (coords) {
          setCoords({ lat: coords.latitude, lng: coords.longitude });
        }
      },
      err => console.log(err.message),
      // { enableHighAccuracy: true },
    );
    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  navigator?.permissions
    ?.query({ name: 'geolocation' })
    .then(clientResponse => {
      if (clientResponse.state === 'granted') {
        return setIsUsable(true);
      }
      if (clientResponse.state === 'denied') {
        return setIsUsable(true);
      }
      return setIsUsable(false);
    });
  return { coords, active: isUsable };
}
