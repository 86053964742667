import Api from 'services/api';

interface IUserDevicesOnGrid {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  hasNextPage: boolean;
  data: {
    id: number;
    imei: string;
  }[];
}

export async function fetchUserDevicesOnGrid(
  page: number,
): Promise<IUserDevicesOnGrid> {
  const response = await Api.get<{
    message: string;
    payload: IUserDevicesOnGrid;
  }>('/user_devices_on_grid', {
    params: {
      page,
      size: 200,
    },
  });
  return response.data.payload;
}

export async function addUserDevicesOnGrid(deviceIds: number[]) {
  const response = await Api.post('/user_devices_on_grid', {
    deviceIds,
  });
  return response;
}

export async function clearUserDevicesOnGrid() {
  await Api.delete('/user_devices_on_grid');
}

export async function removeUserDevicesOnGridByDeviceId(deviceId: number) {
  await Api.delete(`/user_devices_on_grid/${deviceId}`);
}
