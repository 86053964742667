import googleMapReact, { Bounds, Coords, Size } from 'google-map-react';
import * as ReactDOMServer from 'react-dom/server';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { mapThemes } from 'styles/mapThemes';
import { TraceInfoWindowContent } from 'components/Map/TraceInfoWindow';
import { TraceData } from 'types/position';
import { useAuth } from './authProvider';

interface MapProviderProps {
  children: React.ReactNode;
}

export interface GoogleMapsApiProps {
  map: google.maps.Map;
  maps: typeof google.maps;
  ref: Element | null;
}

interface GoogleMapsState {
  center: Coords;
  zoom: number;
  bounds: Bounds;
  marginBounds: Bounds;
  size: Size;
}

interface MapContextProps {
  googleMapsApiProps: GoogleMapsApiProps | undefined;
  mapIsLoaded: boolean;
  onGoogleApiLoaded: (maps: GoogleMapsApiProps) => void;
  onMapChange: (value: googleMapReact.ChangeEventValue) => void;
  addTraceOnMainMap: (traceData: TraceData[]) => void;
  googleMapsState: GoogleMapsState;
}

interface ITraceIcon {
  ignition: boolean;
  speed: number;
  direction: number;
  mapTheme: 'dark' | 'light';
}

interface IGoogleMapsSvg {
  path: string;
  fillColor: string;
  fillOpacity?: number;
  strokeWeight?: number;
  rotation?: number;
  scale?: number;
  stroke?: string;
  fill?: string;
  fillRule?: string;
  anchor?: google.maps.Point;
}

const iconsPath = {
  moving:
    'M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z',
  stoppedIgnitionOn:
    'M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM352 328c0 13.2-10.8 24-24 24h-144C170.8 352 160 341.2 160 328v-144C160 170.8 170.8 160 184 160h144C341.2 160 352 170.8 352 184V328z',
  stoppedIgnitionOff:
    'M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM168 232C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H168z',
};

const polylineColor = {
  dark: '#764AF1',
  light: '#0474fa',
};

function traceIcon({ direction, ignition, speed }: ITraceIcon) {
  const icon: IGoogleMapsSvg = {
    path: '',
    fillColor: '#FFFFFF',
    fillOpacity: 1,
    strokeWeight: 0.2,
    rotation: 0,
    scale: 0.025,
    stroke: 'none',
    fill: '#0474fa',
    fillRule: 'nonzero',
    // fillRule: 'evenodd',
    anchor: new google.maps.Point(250, 250),
  };
  if (ignition) {
    if (speed > 0) {
      icon.path = iconsPath.moving;
      icon.rotation = Number(direction);
    } else {
      icon.path = iconsPath.stoppedIgnitionOn;
    }
  } else {
    icon.path = iconsPath.stoppedIgnitionOff;
  }
  return icon;
}

export const AuthContext = createContext({} as MapContextProps);

const MapProvider = ({ children }: MapProviderProps) => {
  const { user } = useAuth();
  const { userSettings } = user;
  const [googleMapsApiProps, setGoogleMapsApiProps] =
    useState<GoogleMapsApiProps>();

  const [googleMapsState, setGoogleMapsState] = useState<GoogleMapsState>(
    {} as GoogleMapsState,
  );

  const [mapIsLoaded, setMapIsLoaded] = useState(false);

  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(
    null,
  );

  const [traceOnMainMap, setTraceOnMainMap] =
    useState<google.maps.Polyline | null>(null);
  const [traceMarkers, setTraceMarkers] = useState<google.maps.Marker[] | null>(
    null,
  );

  useEffect(() => {
    const bounds = googleMapsApiProps?.map?.getBounds();
    const { zoom } = googleMapsState;

    traceMarkers?.forEach(t => {
      if (zoom < 16) {
        t.setVisible(false);
        return;
      }
      const position = t.getPosition() as google.maps.LatLng;
      if (bounds?.contains(position)) {
        t.setVisible(true);
      } else {
        t.setVisible(false);
      }
    });
  }, [googleMapsApiProps?.map, googleMapsState, traceMarkers]);

  useEffect(() => {
    const isDarkTheme = userSettings?.map?.theme === 'dark';
    googleMapsApiProps?.map?.setOptions({
      styles: isDarkTheme ? mapThemes.night : mapThemes.standard,
    });
    traceOnMainMap?.setOptions({
      strokeColor: isDarkTheme ? polylineColor.dark : polylineColor.light,
    });
  }, [googleMapsApiProps?.map, traceOnMainMap, userSettings?.map?.theme]);

  const onGoogleApiLoaded = ({ maps, map, ref }: GoogleMapsApiProps) => {
    const isDarkTheme = user?.userSettings?.map?.theme === 'dark';
    if (map) {
      map.setOptions({
        styles: isDarkTheme ? mapThemes.night : mapThemes.standard,
      });
      const polyline = new maps.Polyline({
        map,
        geodesic: true,
        strokeColor: isDarkTheme ? polylineColor.dark : polylineColor.light,
        strokeOpacity: 5.0,
        strokeWeight: 4,
      });
      const infowindow = new maps.InfoWindow({});
      maps.event.addListener(infowindow, 'domready', () => {
        const iwElement = document.getElementsByClassName('gm-style-iw');
        const btn = iwElement[0].querySelector('button');
        btn?.remove();
      });
      setGoogleMapsApiProps({ map, maps, ref });
      setTraceOnMainMap(polyline);
      setInfoWindow(infowindow);
      setMapIsLoaded(true);
    }
  };

  const onMapChange = useCallback((value: googleMapReact.ChangeEventValue) => {
    setGoogleMapsState(value);
  }, []);

  const addTraceOnMainMap = useCallback(
    (traceData: TraceData[]) => {
      if (!googleMapsApiProps) {
        console.log('google maps api not ready');
        return;
      }

      const path = traceData.map(t => ({
        lat: Number(t.latitude),
        lng: Number(t.longitude),
      }));

      const isDarkTheme = userSettings?.map?.theme === 'dark';
      traceOnMainMap?.setPath(path);
      traceOnMainMap?.setOptions({
        strokeColor: isDarkTheme ? polylineColor.dark : polylineColor.light,
      });

      const markers = traceData.map((p, index) => {
        const newMarker = new googleMapsApiProps.maps.Marker({
          position: { lat: Number(p.latitude), lng: Number(p.longitude) },
          map: googleMapsApiProps.map,
          icon: traceIcon({
            direction: p.direction,
            ignition: p.ignition,
            speed: p.speed,
            mapTheme: userSettings.map?.theme || 'dark',
          }),
          zIndex: 10000,
          optimized: true,
        });

        newMarker.addListener('mouseover', () => {
          const content = ReactDOMServer.renderToString(
            TraceInfoWindowContent({ ...p, index: index + 1 }),
          );
          infoWindow?.setContent(content);
          infoWindow?.open({
            anchor: newMarker,
            map: googleMapsApiProps.map,
            shouldFocus: false,
          });
        });

        newMarker.addListener('onclick', () => {
          const content = ReactDOMServer.renderToString(
            TraceInfoWindowContent({ ...p, index: index + 1 }),
          );
          infoWindow?.setContent(content);
          infoWindow?.open({
            anchor: newMarker,
            map: googleMapsApiProps.map,
            shouldFocus: false,
          });
        });

        newMarker.addListener('mouseout', () => {
          infoWindow?.close();
        });

        return newMarker;
      });

      setTraceMarkers(old => {
        old?.forEach(i => i.setMap(null));
        return markers;
      });
    },
    [googleMapsApiProps, infoWindow, traceOnMainMap, userSettings?.map?.theme],
  );

  return (
    <AuthContext.Provider
      value={{
        googleMapsApiProps,
        onGoogleApiLoaded,
        addTraceOnMainMap,
        onMapChange,
        mapIsLoaded,
        googleMapsState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useGoogleMaps() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useGoogleMaps must be used within an MapProvider');
  }

  return context;
}

export { MapProvider, useGoogleMaps };
