import { createContext, ReactNode, useCallback, useContext } from 'react';

import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
  VariantType,
  WithSnackbarProps,
} from 'notistack';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

type ToastOptions = Omit<OptionsObject, 'variant' | 'message'> | undefined;
interface ToastContextProps {
  addToast(
    type: VariantType,
    message: SnackbarMessage,
    options?: ToastOptions,
  ): void;
  closeToast: (key: SnackbarKey) => void;
}

const ToastContext = createContext({} as ToastContextProps);

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addToast = useCallback(
    (type: VariantType, message: SnackbarMessage, options?: ToastOptions) => {
      const { action, ...rest } = options || { action: null };
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        ...rest,
        action: key => (
          <>
            {typeof action === 'function' ? (
              action(key)
            ) : (
              <IconButton key={1} onClick={() => closeSnackbar(key)}>
                <Close />
              </IconButton>
            )}
          </>
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );
  return (
    <>
      <SnackbarUtilsConfigurator />
      <ToastContext.Provider value={{ addToast, closeToast: closeSnackbar }}>
        {children}
      </ToastContext.Provider>
    </>
  );
};

function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastProvider');
  }

  return context;
}

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = ({
  setUseSnackbarRef,
}: IProps) => {
  setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

export { ToastProvider, useToast };
export default {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  warning(msg: string) {
    this.toast(msg, 'warning');
  },
  info(msg: string) {
    this.toast(msg, 'info');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 8000,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  },
};
