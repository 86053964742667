import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { IDecodedToken, SharedTrackerService } from 'services/sharedTracker';
import { storage } from 'utils/storage';
import { useStyles } from './styles/globalShareTracker';

interface IVerifyTokenProps {
  token: string;
  handleDecodedToken: (data: IDecodedToken) => void;
}

export function VerifyToken({ token, handleDecodedToken }: IVerifyTokenProps) {
  const classes = useStyles();

  const [state, setStage] = useState('Validando link...');

  const verifyToken = useCallback(async () => {
    if (!token) {
      setStage('Link inválido!');
      return;
    }

    try {
      const resp = await SharedTrackerService.verifyToken(token);
      const { deviceId, plate, exp, fullShare } = resp.data.payload;

      const expiresIn = new Date(exp * 1000);
      const expired = new Date() > expiresIn;

      if (expired) {
        setStage('Link expirado!');
      } else {
        setStage(`tracking ${deviceId}`);
        handleDecodedToken({
          token,
          deviceId,
          plate,
          exp,
          expIn: expiresIn,
          expired,
          fullShare,
          iat: 0,
          sub: undefined,
        });
        storage.setItem({
          key: 'token',
          storageType: 'session',
          values: token,
        });
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        if (error.response?.data?.message === 'Token expirado') {
          setStage('Link expirado');
          return;
        }
        setStage('Link inválido');
        return;
      }
      setStage('Houve um problema na validção do link');
    }
  }, [token, handleDecodedToken]);

  useEffect(() => {
    setTimeout(() => {
      verifyToken();
    }, 3000);
  }, [verifyToken]);

  return (
    <div className={classes.root}>
      <Dialog
        open
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="body1">{state}</Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
