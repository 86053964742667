import { IconButton } from '@material-ui/core';
import { Close, PinDrop } from '@material-ui/icons';
import { useUntreatedEvents } from 'hooks/useEvents';
import { useMobileResolution } from 'hooks/useMobileResolution';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { TEventOccurrences, TUntreatedEvent } from 'types/event';
import { playSound } from 'utils/playSound';
import { useAuth } from './authProvider';
import { useToast } from './toastProvider';

interface EventsContextProps {
  emergencyEvents: TUntreatedEvent | undefined;
  emergencyEventsTotal: number;
  selectedEmergencyEvent: TEventOccurrences | null;
  handleNotifyEvent(events: TUntreatedEvent): void;
  setEmergencyEventsEnabled: Dispatch<SetStateAction<boolean>>;
}

export const URGENT_EVENTS = [
  'alarm_against_external_power_cut',
  'movement_alarm',
  'light_sensor_violation',
  'sos_alarm',
];

const EventsContext = createContext({} as EventsContextProps);

const EventsProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const { user, isAuthenticated } = useAuth();
  const isMobile = useMobileResolution();

  const [emergencyEventsEnabled, setEmergencyEventsEnabled] = useState(true);

  const lastNotifiedRef = useRef<Date | null>();

  const [selectedEmergencyEvent, setSelectedEmergencyEvent] =
    useState<TEventOccurrences | null>(null);

  const { addToast, closeToast } = useToast();

  const {
    query: { data: emergencyEvents },
  } = useUntreatedEvents({
    eventTypes: URGENT_EVENTS,
    eventsSize: 50,
    options: {
      refetchIntervalInBackground: emergencyEventsEnabled,
      enabled: isAuthenticated && !isMobile && !user.isMasterCompany,
    },
  });

  const { totalItems: emergencyEventsTotal } =
    emergencyEvents as TUntreatedEvent;

  const handleNotifyEvent = useCallback(
    (events: TUntreatedEvent) => {
      if (!lastNotifiedRef.current) {
        return;
      }
      let lastEventDate: Date | undefined;

      const newEvents = events.events.reduce((acc, current) => {
        const eventDate = new Date(current.dateSystem);
        const lastNotifiedDate = lastNotifiedRef.current as Date;
        if (eventDate > lastNotifiedDate) {
          if (lastEventDate) {
            if (eventDate > lastEventDate) {
              lastEventDate = eventDate;
            }
          } else {
            lastEventDate = eventDate;
          }
          return [...acc, current];
        }
        return acc;
      }, [] as unknown as TEventOccurrences[]);

      if (newEvents.length) {
        if (lastEventDate) {
          lastNotifiedRef.current = lastEventDate;
        }
        if (!user.userSettings?.emergencyEvents?.notification) {
          return;
        }
        if (user.userSettings?.emergencyEvents?.sound) {
          playSound();
        }

        newEvents
          .sort((a, b) => {
            if (a.dateSystem < b.dateSystem) {
              return 1;
            }
            if (a.dateSystem > b.dateSystem) {
              return -1;
            }
            return 0;
          })
          .slice(0, 5)
          .reverse()
          .forEach(e => {
            addToast(
              'error',
              `${e.event} | ${e.dateSystem.split(' ')[1]} | ${e.plate}`,
              {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                autoHideDuration: null,
                action: key => {
                  return (
                    <>
                      <IconButton
                        key={e.id}
                        onClick={() => {
                          setSelectedEmergencyEvent(e);
                          const location = {
                            pathname: '/map',
                            state: {
                              event: e,
                            },
                          };
                          history.push(location);
                        }}
                      >
                        <PinDrop fontSize="small" />
                      </IconButton>
                      <IconButton
                        key={`close-${e.id}`}
                        onClick={() => {
                          closeToast(key);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </>
                  );
                },
              },
            );
          });
      }
    },
    [addToast, closeToast, history, user.userSettings?.emergencyEvents],
  );

  useEffect(() => {
    if (lastNotifiedRef.current) {
      if (emergencyEvents?.events.length) {
        handleNotifyEvent(emergencyEvents);
      }
    } else if (emergencyEventsTotal) {
      if (user.userSettings?.emergencyEvents?.sound) {
        playSound();
      }
      lastNotifiedRef.current = new Date();
      addToast(
        'error',
        `${emergencyEventsTotal} eventos aguardando tratamento!`,
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 5000,
        },
      );
    }
  }, [
    addToast,
    closeToast,
    emergencyEvents,
    emergencyEvents?.events,
    emergencyEventsTotal,
    handleNotifyEvent,
    history,
    user.userSettings?.emergencyEvents?.sound,
  ]);

  return (
    <EventsContext.Provider
      value={{
        emergencyEvents,
        emergencyEventsTotal,
        selectedEmergencyEvent,
        handleNotifyEvent,
        setEmergencyEventsEnabled,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

function useEventsContext() {
  const context = useContext(EventsContext);

  if (!context) {
    throw new Error('useEventsContext must be used within an EventsProvider');
  }

  return context;
}

export { EventsProvider, useEventsContext };
