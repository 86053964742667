/* eslint-disable import/prefer-default-export */
import { AuthUser } from 'types/index.js';
import Api from './api';

interface ILoginResponse {
  message: string;
  payload: {
    token: string;
    refreshToken: string;
    user: AuthUser;
  };
}

export const Session = {
  login: ({ login, password }: { login: string; password: string }) =>
    Api.post<ILoginResponse>('/session', { login, password }),
  logout: (token: string) => Api.delete(`/session/${token}`),
};
