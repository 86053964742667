import { AxiosResponse } from 'axios';
import { TUserSettings } from 'types/user';
import Api from './api';

export const UserSettingsService = {
  updateByUserId: (
    userId: number,
    data: TUserSettings,
  ): Promise<AxiosResponse<{ message: string }>> =>
    Api.put(`/users_settings/${userId}`, data),
};
