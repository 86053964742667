import React from 'react';
import { Box } from '@material-ui/core';
import { useAuth } from 'context/authProvider';

import logo from 'assets/one_icon.png';
import { useMobileResolution } from 'hooks/useMobileResolution';
import { useHistory } from 'react-router-dom';
import { useCustomTheme } from 'context/themeProvider';
import { MenuLinks } from './Links';

import { useStyles } from './styles';
import { SettingsDialog } from './SettingsDialog';

export function Sidebar() {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const isMobile = useMobileResolution();
  const { appTheme: currentTheme } = useCustomTheme();
  const classes = useStyles({ isMobile, currentTheme });

  if (!isAuthenticated || history.location.pathname.includes('shared_tracking'))
    return null;
  return (
    <Box className={classes.root} component="nav">
      <Box className={classes.logo}>
        <img
          src="#"
          alt="logo"
          width={30}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = logo;
          }}
        />
      </Box>
      <section className={classes.navigation}>
        <MenuLinks />

        <SettingsDialog />
      </section>
    </Box>
  );
}
