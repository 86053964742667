type ValidaUserPermissionsParams = {
  user: any;
  permissions?: string[];
  roles?: string[];
};

export function validateUserPermissions({
  user,
  permissions,
}: ValidaUserPermissionsParams): boolean {
  if (permissions && permissions?.length > 0) {
    const hasAllPermissions = permissions.every(permission => {
      return user?.permissions?.includes(permission);
    });

    if (!hasAllPermissions) {
      return false;
    }
  }

  return true;
}
