/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';
import { storage } from 'utils';
import { REACT_APP_API_URL } from '../config';
import toast from '../context/toastProvider';
import { AuthTokenError } from './errors/AuthTokenError.js';

type TFailedRequest = {
  onSuccess: (token: String) => void;
  onFailure: (error: any) => void;
};

let isRefreshing = false;
let failedRequestsQueue: TFailedRequest[] = [];

const Api = axios.create({
  baseURL: REACT_APP_API_URL,
});

function getToken() {
  const localToken = storage.getItem({ storageType: 'local', key: 'token' });

  if (localToken) {
    return localToken;
  }

  const sessionToken = storage.getItem({
    storageType: 'session',
    key: 'token',
  });

  if (sessionToken) {
    return sessionToken;
  }

  return null;
}

function getRefreshToken(): {
  refreshToken: string | null;
  type: 'local' | 'session';
} {
  const localRefreshToken = storage.getItem({
    storageType: 'local',
    key: 'refresh_token',
  });

  if (localRefreshToken) {
    return {
      refreshToken: localRefreshToken,
      type: 'local',
    };
  }

  const sessionRefreshToken = storage.getItem({
    storageType: 'session',
    key: 'refresh_token',
  });

  if (sessionRefreshToken) {
    return {
      refreshToken: sessionRefreshToken,
      type: 'session',
    };
  }

  return { refreshToken: null, type: 'session' };
}

function clearStorage() {
  storage.clearAll();
}

function setTokens({
  type = 'session',
  token,
  refreshToken,
  user,
}: {
  token: string;
  refreshToken: string;
  type: 'session' | 'local';
  user: any;
}) {
  storage.setItem({ key: 'user', storageType: type, values: user });
  storage.setItem({
    key: 'refresh_token',
    storageType: type,
    values: refreshToken,
  });
  storage.setItem({
    key: 'token',
    storageType: type,
    values: token,
  });
}

Api.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

Api.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  async (reqError: AxiosError<any>) => {
    const originalConfig = reqError.config;

    if (!reqError.response) {
      return Promise.reject(reqError);
    }

    if (reqError.response.status !== 401) {
      if (reqError.message === 'Network Error') {
        toast.error(
          'Parece que há um problema de conexão, tente novamente mais tarde.',
        );
        return Promise.reject();
      }
      return Promise.reject(reqError);
    }

    if (originalConfig.url?.includes('/session/refresh')) {
      return Promise.reject(reqError);
    }

    const code = reqError.response.data?.code;

    if (code === 'token.expired' || code === 'token.invalid') {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: token => {
              if (originalConfig.headers) {
                originalConfig.headers.Authorization = `Bearer ${token}`;
              } else {
                originalConfig.headers = {
                  Authorization: `Bearer ${token}`,
                };
              }
              resolve(Api(originalConfig));
            },
            onFailure: error => {
              reject(error);
            },
          });
        });
      }

      isRefreshing = true;

      const { refreshToken, type } = getRefreshToken();

      try {
        const response = await Api.post(`/session/refresh`, {
          refreshToken,
        });

        const {
          token,
          user,
          refreshToken: newRefreshToken,
        } = response.data.payload;

        setTokens({ type, user, token, refreshToken: newRefreshToken });

        Api.defaults.headers.common.Authorization = `Bearer ${token}`;
        failedRequestsQueue.forEach(request => request.onSuccess(token));
        failedRequestsQueue = [];
        return Promise.resolve(Api(originalConfig));
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          toast.error('Sua sessão expirou. Por favor, faça o login novamente.');

          setTimeout(() => {
            clearStorage();
            window.location.href = '/';
          }, 8000);
        }

        failedRequestsQueue.forEach(request => request.onFailure(error));
        failedRequestsQueue = [];
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    } else {
      toast.error(
        'Houve um problema na validação das suas credenciais, você será deslogado.',
      );

      setTimeout(() => {
        clearStorage();
        window.location.href = '/';
      }, 8000);

      return Promise.reject(new AuthTokenError());
    }
  },
);

export const instance = axios.create({});
delete instance.defaults.headers.common.Authorization;

export default Api;
