import { useQuery } from 'react-query';
import { IDecodedToken, SharedTrackerService } from 'services/sharedTracker';
import { TPosition } from 'types/position';
import { Coords } from 'google-map-react';
import { useEffect, useState } from 'react';
import { PositionsService } from 'services/positions';

async function fetchLastPosition({
  deviceId,
  token,
}: {
  deviceId: string;
  token: string;
}): Promise<TPosition> {
  const response = await SharedTrackerService.lastPosition(deviceId, token);
  return response.data.payload;
}

type Props = {
  decodedToken: IDecodedToken;
  onSuccess?: (data: TPosition) => void;
};

export function useCarInfo({ decodedToken, onSuccess }: Props) {
  const [traceCar, setTraceCar] = useState<Coords[]>();

  const { data: carInfo, isFetched } = useQuery(
    ['shared_tracker', decodedToken],
    () =>
      fetchLastPosition({
        deviceId: decodedToken?.deviceId as string,
        token: decodedToken?.token as string,
      }),
    {
      initialData: undefined,
      enabled: !!decodedToken?.deviceId,
      refetchInterval: 60000,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        if (onSuccess) onSuccess(data);
        setTraceCar(coords => [
          ...(coords?.length ? coords : []),
          {
            lat: Number(data.latitude),
            lng: Number(data.longitude),
          },
        ]);
      },
    },
  );
  useEffect(() => {
    (async () => {
      const now = new Date();
      if (!decodedToken || !decodedToken.deviceId) return;
      const trace = await PositionsService.getTraceTimeSeries({
        betweenDates: undefined,
        imei: decodedToken.deviceId,
        hours: undefined,
        limitPositions: 25,
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      });
      const tracePath = trace.data.payload.map(
        (e: { latitude: string; longitude: string }) => ({
          lat: Number(e.latitude),
          lng: Number(e.longitude),
        }),
      );
      setTraceCar(tracePath);
    })();
  }, [decodedToken]);

  return { carInfo, isFetched, traceCar };
}
