import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useStyles } from './styles';

interface EventLabelProps {
  event: string;
  occurrences: number;
  status: string;
}

export function EventLabel({ status, event, occurrences }: EventLabelProps) {
  const isPending = status === 'pending';
  const classes = useStyles();
  return (
    <>
      ({occurrences}) {event}
      {isPending && (
        <Tooltip title="Tratamento pendente" arrow>
          <Info className={classes.icon} />
        </Tooltip>
      )}
    </>
  );
}
