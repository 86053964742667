import { useAuth } from 'context/authProvider';
import { useToast } from 'context/toastProvider';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { EventService } from 'services/events.js';
import {
  TEvent,
  TEventOccurrences,
  TEventTypes,
  TTreatedEvent,
  TUntreatedEvent,
} from 'types/event';

const REFETCH_INTERVAL = 2 * 60000;
// const REFETCH_BACKGROUND_INTERVAL = 10 * 60000;

interface FetchUntreatedEventsProps {
  page: number;
  size: number;
  companyId: number;
  eventTypes: string[];
}

interface FetchEventsByImeiProps {
  imei: string;
  eventTypes: string[];
  treated: boolean;
  read: boolean | undefined;
  ignorePending?: boolean;
}

interface ICreateTreatedEvent {
  imei: string;
  comment: string;
  status: string;
  amount: number;
  initialDate: Date;
  finalDate?: Date | undefined;
  eventIds: string[];
  treated: boolean;
}

interface IUpdateTreatedEvent {
  treatmentId: string;
  comment?: string;
  status?: string;
  amount?: number;
  finalDate?: Date;
  eventIds: string[];
  treated: boolean;
}

interface useCreateTreatedEventProps {
  onSuccessAction: () => void;
}

async function fetchUntreatedEvents({
  page,
  size,
  companyId,
  eventTypes,
}: FetchUntreatedEventsProps): Promise<TUntreatedEvent> {
  const response = await EventService.untreatedEvents({
    page: page + 1,
    size,
    companyId,
    eventType: eventTypes,
  });
  const { payload } = response.data;
  return payload;
}

async function fetchEventsByImei({
  imei,
  eventTypes,
  treated,
  read,
  ignorePending,
}: FetchEventsByImeiProps): Promise<TEvent[]> {
  const response = await EventService.eventsByImei({
    imei,
    eventTypes,
    treated,
    read,
    ignorePending,
  });
  const { payload } = response.data;
  return payload;
}

async function fetchOccurrenceEventsByImei(
  imei: string,
): Promise<TEventOccurrences[]> {
  const response = await EventService.occurenceUntreatedEventsByImei(imei);
  return response.data.payload;
}

async function createTreatedEvent(data: ICreateTreatedEvent) {
  return EventService.createTreatedEvent(data);
}

async function UpdateTreatedEvent(data: IUpdateTreatedEvent) {
  return EventService.updateTreatedEvent(data.treatmentId, data);
}

async function fetchTreatedEvent(imei: string): Promise<TTreatedEvent[]> {
  const response = await EventService.listTreatedEvent(imei);
  return response.data.payload;
}

async function fetchTreatmentEvents(treatmentId: string | undefined) {
  const response = await EventService.listTreatmentEvents(treatmentId);
  return response.data.payload;
}

async function fetchEventTypes(): Promise<TEventTypes[]> {
  const response = await EventService.eventTypes();
  return response.data.payload;
}

export function useUntreatedEvents({
  eventTypes,
  eventsSize,
  options,
}: {
  eventTypes: string[];
  eventsSize?: number;
  options?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;
}): {
  query: UseQueryResult<TUntreatedEvent>;
  page: number;
  size: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSize: Dispatch<SetStateAction<number>>;
} {
  // const queryClient = useQueryClient();
  const { user } = useAuth();
  const { permissions } = user;
  const hasPermission = permissions
    ? permissions.includes('event.monitor')
    : false;

  const isEventMonitorEnable = hasPermission && !user.isMasterCompany;

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(eventsSize || 5);

  const { companyId } = user;
  const query = useQuery(
    ['untreatedEvents', companyId, page, size, eventTypes],
    () =>
      fetchUntreatedEvents({
        page,
        size,
        companyId,
        eventTypes,
      }),
    {
      initialData: {
        events: [],
        currentPage: 0,
        hasNextPage: false,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
      },
      keepPreviousData: true,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: isEventMonitorEnable,
      ...options,
    },
  );

  return { query, page, setPage, size, setSize };
}

export function useUpdateEvent() {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const mutation = useMutation(
    async ({ id, data }: { id: string; data: { [key: string]: any } }) => {
      await EventService.updateEvent(id, data);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('untreatedEvents');
      },
      onSuccess: async () => {
        addToast('success', 'Evento atualizado.');
        queryClient.invalidateQueries('untreatedEvents');
      },
      onError: async (error, context) => {
        console.log(error);
        addToast('error', 'Algo deu errado.');
        queryClient.setQueryData('untreatedEvents', context);
      },
      retry: 1,
    },
  );
  return mutation;
}

export function useDeleteEvent() {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const mutation = useMutation(
    async id => {
      await EventService.deleteEvent(id);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('untreatedEvents');
      },
      onSuccess: async () => {
        addToast('success', 'Evento descartado.');
        queryClient.invalidateQueries('untreatedEvents');
      },
      onError: async (error, context) => {
        console.log(error);
        addToast('error', 'Algo deu errado.');
        queryClient.setQueryData('untreatedEvents', context);
      },
      retry: 1,
    },
  );
  return mutation;
}

export function useFindEventsByImei({
  imei,
  eventTypes,
  treated,
  read,
  ignorePending,
}: {
  imei: string;
  eventTypes: string[];
  treated: boolean;
  read: boolean | undefined;
  ignorePending?: boolean;
}): UseQueryResult<TEvent[]> {
  return useQuery(
    ['untreatedEventsByImei', imei, eventTypes, treated, read, ignorePending],
    async () => {
      return fetchEventsByImei({
        imei,
        eventTypes,
        treated,
        read,
        ignorePending,
      });
    },
    {
      initialData: [],
      enabled: !!(imei && eventTypes?.length),
      // refetchInterval: REFETCH_INTERVAL,
      // refetchIntervalInBackground: REFETCH_BACKGROUND_INTERVAL,
      refetchOnWindowFocus: false,
    },
  );
}

export function useOccurrenceEventsByImei({ imei }: { imei: string }) {
  return useQuery(
    ['occurenceEvents', imei],
    () => fetchOccurrenceEventsByImei(imei),
    {
      initialData: [],
      enabled: !!imei,
      // refetchInterval: REFETCH_INTERVAL,
      // refetchIntervalInBackground: REFETCH_BACKGROUND_INTERVAL,
      refetchOnWindowFocus: false,
    },
  );
}

export function useEventTypes(): UseQueryResult<TEventTypes[]> {
  return useQuery(['eventTypes'], () => fetchEventTypes(), {
    initialData: [],
    refetchOnWindowFocus: false,
  });
}

export function useTreatedEvents({ imei }: { imei: string }) {
  return useQuery(['treatedEvents', imei], () => fetchTreatedEvent(imei), {
    initialData: [],
    enabled: !!imei,
    refetchOnWindowFocus: false,
  });
}

export function useTreatmentEvents({
  treatmentId,
}: {
  treatmentId: string | undefined;
}) {
  return useQuery(
    ['treatmentEvents', treatmentId],
    () => treatmentId && fetchTreatmentEvents(treatmentId),
    {
      initialData: [],
      enabled: !!treatmentId,
      refetchOnWindowFocus: false,
    },
  );
}

export function useCreateTreatedEvent({
  onSuccessAction,
}: useCreateTreatedEventProps) {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const mutation = useMutation({
    mutationFn: createTreatedEvent,
    onMutate: async () => {
      await queryClient.cancelQueries('untreatedEvents');
    },
    onSuccess: async () => {
      addToast('success', 'Tratamento concluído.');
      queryClient.invalidateQueries('untreatedEvents');
      queryClient.invalidateQueries('untreatedEventsByImei');
      queryClient.invalidateQueries('occurenceEvents');
      queryClient.invalidateQueries('treatedEvents');
      onSuccessAction();
    },
    onError: async error => {
      console.log(error);
      addToast('error', 'Algo deu errado.');
    },
    retry: 1,
  });
  return mutation;
}

export function useUpdateTreatedEvent({
  onSuccessAction,
}: useCreateTreatedEventProps) {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const mutation = useMutation({
    mutationFn: UpdateTreatedEvent,
    onMutate: async () => {
      await queryClient.cancelQueries('untreatedEvents');
    },
    onSuccess: async () => {
      addToast('success', 'Tratamento atualizado.');
      queryClient.invalidateQueries('untreatedEvents');
      queryClient.invalidateQueries('untreatedEventsByImei');
      queryClient.invalidateQueries('occurenceEvents');
      queryClient.invalidateQueries('treatedEvents');
      onSuccessAction();
    },
    onError: async error => {
      console.log(error);
      addToast('error', 'Algo deu errado.');
    },
    retry: 1,
  });
  return mutation;
}
