import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  bodyItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    paddingTop: 0,
    gap: 12,
    '& p': {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      lineHeight: '120%',
      color: '#4f4e4e',
      marginTop: '5px',
      gap: 5,
    },
  },
  iconTexts: {
    fontSize: 18,
  },
});
