// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
  Typography,
  Container,
  InputAdornment,
  FormControl,
  IconButton,
  Paper,
  CircularProgress,
  createTheme,
  ThemeProvider,
  colors,
} from '@material-ui/core';
import { Visibility, VisibilityOff, Check } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from 'context/authProvider';

import logo from 'assets/one_logo_full.png';

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMobileResolution } from 'hooks/useMobileResolution';
import { REACT_APP_LOGO_BASE_URL } from 'config';
import { useStyles } from './styles';

const loginFormSchema = yup.object().shape({
  login: yup.string().required('Login obrigatório.'),
  password: yup
    .string()
    .required('Senha obrigatória.')
    .min(6, 'Mínimo de 6 dígitos.'),
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        LW SIM
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

function Logo({
  classes,
}: {
  classes: { logo: string; containerLogo: string };
}) {
  const subDomain = window.location.hostname.split('.').slice(0, 2).join('.');

  return (
    <div className={classes.containerLogo}>
      <img
        src={`${REACT_APP_LOGO_BASE_URL}${subDomain}_light.png`}
        alt="logo-light"
        className={classes.logo}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = logo;
        }}
      />
    </div>
  );
}

export default function Login() {
  const classes = useStyles();
  const { login, success, isAuthenticated } = useAuth();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const isMobile = useMobileResolution();
  const redirectPath = isMobile ? '/map' : '/dashboard';

  if (isAuthenticated) {
    history.push(redirectPath);
  } else {
    localStorage.clear();
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      login: '',
      password: '',
      rememberMe: false,
    },
    resolver: yupResolver(loginFormSchema),
  });

  const buttonClassname = clsx({
    [classes.submit]: !success,
  });

  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px rgba(56, 145, 252, .3) inset',
            '-webkit-text-fill-color': colors.grey[700],
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.loginBGI}>
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Logo classes={classes} />
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(login)}
            >
              <Controller
                name="login"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    // autoComplete="new-password"
                    required
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="login"
                    label="Login"
                    type="text"
                    autoFocus
                    error={!!errors.login}
                    helperText={errors.login?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth {...field}>
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="outlined-adornment-password"
                      label="Senha"
                      type={showPassword ? 'text' : 'password'}
                      autoFocus
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(prev => !prev)}
                              onMouseDown={e => e.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility color="primary" />
                              ) : (
                                <VisibilityOff color="primary" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                )}
              />

              <Controller
                name="rememberMe"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    color="secondary"
                    control={
                      <Checkbox size="small" color="primary" {...field} />
                    }
                    label="Lembrar de mim"
                  />
                )}
              />

              <Box className={classes.wrapper}>
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="default"
                  className={buttonClassname}
                  disabled={isSubmitting}
                >
                  {success ? <Check /> : 'Entrar'}
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            </form>
          </Paper>
          <Box mt={8} className={classes.copyright}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}
