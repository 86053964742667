import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme: { breakpoints: { down: (arg0: number) => any } }) =>
    createStyles({
      wrapper: {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: 0,
        background: 'rgb(0 0 0 /50%)',
      },
      paper: {
        background: '#fff',
        borderRadius: '0px 20px 20px 0px',
        height: '100vh',
        width: '85vw',
        maxWidth: '300px',
        maxHeight: '100vh',
      },
      buttonEvents: {
        border: 'none',
        background: 'none',
        [theme.breakpoints.down(325)]: {
          width: 55,
        },
      },
      headerEventCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      textHeaderEventCard: {
        color: '#676767',
        fontSize: '30px',
        fontWeight: 700,
      },
      eventsContent: {
        overflowY: 'scroll',
        height: '75vh',
        gap: 12,
        display: 'grid',
        marginTop: '20px',
      },
      buttonEventsHideCard: {
        all: 'unset',
        backgroundColor: '#2A99D7',
        color: '#f4f1f1',
        boxShadow: '#060606 0px 2px 8px 0px',
        border: '1px solid rgba(8, 189, 249, 0.7)',
        borderRadius: '50%',
        fontSize: '22px',
        width: '2rem',
        height: '2rem',
        display: 'flex',
        placeContent: 'center',
        cursor: 'pointer',
        marginTop: '4px',
        '& > *': {
          margin: 'auto',
          padding: '0',
        },
      },
      iconEvents: {
        display: 'grid',
        fontSize: '36px',
        position: 'relative',
        color: '#2A99D7',
      },
      iconEventsHideCard: {
        display: 'grid',
        fontSize: '22px',
        position: 'relative',
        fill: '#f4f1f1',
      },
    }),
);
