import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useContext } from 'react';
import { coordsCenterMap } from 'pages/SharedTracking/SharedTrackerMap';
import { IHeaderProps } from '../Map/types';
import { CardCarInfo } from '../Card/CardCarInfo';
import { useStyles } from './styles';
import { FixedButton } from '../Card/FixedButton';

export function Header({
  expiresIn,
  lat,
  lng,
  handleInvalidateToken,
  deviceId,
  deviceData,
  handleActiveTrace,
  onClick,
  fullShare,
}: IHeaderProps) {
  const { currentMapCenter } = useContext(coordsCenterMap);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(451));
  const classes = useStyles({});

  const carHasCenter = () => {
    return !(
      currentMapCenter?.lat.toFixed(7) === lat &&
      currentMapCenter?.lng.toFixed(7) === lng
    );
  };

  return (
    <Box>
      {matches && carHasCenter() ? (
        <section className={classes.fixedButtonContainer}>
          <FixedButton onClick={onClick} />
        </section>
      ) : null}
      <section>
        <CardCarInfo
          fullShare={fullShare}
          expiresIn={expiresIn}
          handleInvalidateToken={handleInvalidateToken}
          handleActiveTrace={handleActiveTrace}
          deviceId={deviceId}
          deviceData={deviceData}
          onClick={onClick}
          lat={lat}
          lng={lng}
        />
      </section>
    </Box>
  );
}
