import pkg from '../../package.json';

export const STORAGE_PREFIX = '@light_web_admin:';
export const REACT_APP_GOOGLE_API_KEY =
  'AIzaSyDAawxkZDmj0WadNU78F0HXC1cWHt0SiDk';
export const REACT_APP_API_URL = 'https://light-admin.one.srv.br';
// export const REACT_APP_API_URL = 'http://localhost:3333';
// export const REACT_APP_API_URL = 'http://10.102.98.34:3333';
export const REACT_APP_LOGO_BASE_URL =
  'https://monitoramento-assets.s3.amazonaws.com/logo/';

export const REACT_APP_VERSION = pkg.version as string;
