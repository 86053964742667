import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      border: 'none',
      background: 'transparent',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      cursor: 'none',
      zIndex: 2000,

      borderRadius: '50%',
      padding: 0,
    },
    iconButton: {
      padding: 0,
      margin: 0,
      zIndex: 2500,
    },
    iconPersonMarker: {
      color: '#4caf50',
      padding: 0,
      margin: 0,
    },
  }),
);
