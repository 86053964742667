/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { CSSProperties } from 'react';
import { HeadingComponent } from 'react-markdown/lib/ast-to-react';

type ChildrenHeading = {
  props: {
    href: string;
  };
};

export const Headings: HeadingComponent = ({ level, children }) => {
  const anchor = (children[0] as ChildrenHeading).props?.href
    .replace('#', '')
    .replaceAll('/', '-');

  const container = (children: React.ReactNode): JSX.Element => (
    <a href={`${anchor || '#'}`}>
      <span>{children}</span>
    </a>
  );
  const style: CSSProperties = {
    outline: 'none',
  };
  switch (level) {
    case 1:
      return (
        <h1 style={style} tabIndex={0} id={anchor}>
          {container(children)}
        </h1>
      );
    case 2:
      return (
        <h2 style={style} tabIndex={0} id={anchor}>
          {container(children)}
        </h2>
      );
    case 3:
      return (
        <h3 style={style} tabIndex={0} id={anchor}>
          {container(children)}
        </h3>
      );
    case 4:
      return (
        <h4 style={style} tabIndex={0} id={anchor}>
          {container(children)}
        </h4>
      );
    case 5:
      return (
        <h5 style={style} tabIndex={0} id={anchor}>
          {container(children)}
        </h5>
      );
    default:
      return (
        <h6 style={style} tabIndex={0} id={anchor}>
          {container(children)}
        </h6>
      );
  }
};
