import { useQuery } from 'react-query';
import api from 'services/api';

interface IProps {
  id: number;
  userId: string;
  vehicleId: string;
  type: string;
  synthesis: string;
  contactedName: string;
  contactedPhone: string;
  treatmentDate: Date;
  obs: string;
  createdAt: Date;
  updatedAt: Date;
  vehicle: {
    id: number;
    plate: string;
  };
  user: {
    id: number;
    name: string;
  };
}

export async function getLastByVehicleId(vehicleId: string): Promise<IProps> {
  const response = await api.get(
    `/vehicle_treatment/list_last_by_vehicle_id/${vehicleId}`,
  );

  return response.data.payload;
}

export const useGetLastTreatmentByVehicleId = (vehicleId: string) => {
  return useQuery({
    queryKey: ['vehicles', { vehicleId }],
    queryFn: () => getLastByVehicleId(vehicleId),
  });
};
