import { Theme, makeStyles, colors } from '@material-ui/core';
import loginbackground from 'assets/login-background.png';

export const useStyles = makeStyles((theme: Theme) => ({
  loginBGI: {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${loginbackground})`,
    backgroundSize: 'cover',
  },
  root: {
    // flex: 1,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ffff',
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
    borderRadius: 15,
    height: 480,
    justifyContent: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textFiled: {
    '& .MuiInputBase-root': {
      color: colors.grey[700],
    },
    '& label.Mui-focused': {
      color: '#2068BF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#2068BF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#2068BF',
      },
      '&:hover fieldset': {
        borderColor: '#3891fc',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2068BF',
      },
    },
  },
  textFiledLabel: {
    color: colors.grey[700],
  },
  submit: {
    backgroundColor: '#2068BF',
    margin: theme.spacing(3, 0, 2),
    '&:hover': {
      backgroundColor: '#3891fc',
    },
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    overflow: 'hidden',
  },
  containerLogo: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: colors.blueGrey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -15,
  },
  copyright: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
}));
