import { CancelToken } from 'axios';
import Api from './api.ts';

let source = CancelToken.source();

export const EventService = {
  eventHistory: ({ page, size, initialDate, finalDate, imei }) =>
    Api.get('/admin_events/history', {
      params: {
        page,
        size,
        column: 'imei',
        payload: imei,
        initialDate,
        finalDate,
      },
    }),
  untreatedEvents: ({ page, size, companyId, eventType }) =>
    Api.get('/admin_events/untreated_events', {
      params: { page, size, companyId, eventType },
    }),
  occurenceUntreatedEventsByImei: imei =>
    Api.get(`/admin_events/occurrence_untreated_events_by_imei/${imei}`),
  updateEvent: (id, data) => Api.put(`/admin_events/update/${id}`, data),
  deleteEvent: id => Api.delete(`/admin_events/delete/${id}`),
  eventTypes: () => Api.get('/event_types'),
  eventsByImei: ({
    imei,
    eventTypes,
    treated,
    read,
    ignorePending,
    limit = 0,
  }) => {
    source.cancel('Operation canceled by new request.');
    source = CancelToken.source();
    return Api.get(`/admin_events/by_imei/${imei}`, {
      params: {
        eventTypes,
        treated,
        read,
        ignorePending,
        limit: limit || undefined,
      },
      cancelToken: source.token,
    });
  },
  createTreatedEvent: data => Api.post('/handle_event', data),
  updateTreatedEvent: (treatmentId, data) =>
    Api.put(`/handle_event/${treatmentId}`, data),
  listTreatedEvent: imei => Api.get(`/handle_event/${imei}`),
  listTreatmentEvents: treatmentId =>
    Api.get(`/handle_event/treatment_events/${treatmentId}`),
};
