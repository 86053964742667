/* eslint-disable no-underscore-dangle */
import {
  BatteryChargingFull,
  Build,
  FlashOn,
  Map,
  PinDrop,
  Satellite,
  SignalCellularAlt,
  Snooze,
  Speed,
  VpnKey,
} from '@material-ui/icons';
import { AddressGoogleMapsLink } from 'components/RenderCustomValues/AddressGoogleMapsLink';
import { BatteryPercent } from 'components/RenderCustomValues/BatteryPercent';
import { BatteryVoltage } from 'components/RenderCustomValues/BatteryVoltage';
import { GsmSignalIcon } from 'components/RenderCustomValues/GsmSignal';
import { IsLocked } from 'components/RenderCustomValues/IsLocked';
import { IsOn } from 'components/RenderCustomValues/IsOn';
import { LastCommunicationTime } from 'components/RenderCustomValues/LastCommunicationTime/index.js';
import { SatelliteSignal } from 'components/RenderCustomValues/SatelliteSignal/index.js';
import { SpeedKmh } from 'components/RenderCustomValues/Speed/index.js';
import { TreatmentGrid } from 'components/RenderCustomValues/Treatments';
import { DateTimeFormat } from '../../components/RenderCustomValues/DateTimeFormat/index.js';
import { LatLonFormat } from '../../components/RenderCustomValues/LatLngFormat';

export const deviceGridColumns = [
  {
    id: 1,
    label: 'Id_Disp',
    default: true,
    value: 'imei',
    name: 'device_id',
    minWidth: 50,
    align: 'center',
  },
  {
    id: 2,
    label: 'Fabricante',
    default: true,
    value: 'manufacturer',
    minWidth: 50,
    align: 'center',
    name: 'manufacturer',
  },
  {
    id: 3,
    label: 'Cliente',
    default: true,
    value: 'name',
    name: 'name',
    minWidth: 50,
    align: 'center',
  },
  {
    id: 4,
    label: 'Placa',
    default: true,
    value: 'plate',
    name: 'plate',
    minWidth: 100,
    align: 'center',
    alignRow: 'center',
  },
  {
    id: 6,
    label: 'Data GPS',
    default: true,
    value: 'dateTime',
    name: 'date_time',
    minWidth: 140,
    align: 'center',
    alignRow: 'center',
    render: ({ dateTime }: { dateTime: string }) => (
      <DateTimeFormat date={dateTime} />
    ),
  },
  {
    id: 8,
    label: 'Data Sistema',
    default: true,
    value: 'date_system',
    name: 'dateSystem',
    minWidth: 140,
    align: 'center',
    alignRow: 'center',
    render: ({ dateSystem }: { dateSystem: string }) => (
      <DateTimeFormat date={dateSystem} />
    ),
  },
  {
    id: 7,
    label: 'Tempo sem comunicar',
    default: true,
    value: 'dateTime',
    name: 'date_system_count',
    minWidth: 140,
    align: 'center',
    alignRow: 'center',
    icon: <Snooze style={{ fontSize: 15 }} />,
    render: ({ dateSystem }: { dateSystem: string }) => (
      <LastCommunicationTime dateTime={dateSystem} />
    ),
  },
  {
    id: 9,
    label: 'Latitude',
    default: true,
    value: 'latitude',
    name: 'latitude',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ latitude }: { latitude: string }) => (
      <LatLonFormat coord={latitude} />
    ),
  },
  {
    id: 10,
    label: 'Longitude',
    default: true,
    value: 'longitude',
    name: 'longitude',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ longitude }: { longitude: string }) => (
      <LatLonFormat coord={longitude} />
    ),
  },
  {
    id: 11,
    label: 'Abrir no google maps',
    default: true,
    value: 'longitude',
    name: 'google_maps',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <Map style={{ fontSize: 15 }} />,
    render: ({
      longitude,
      latitude,
    }: {
      longitude: string;
      latitude: string;
    }) => (
      <AddressGoogleMapsLink
        lat={latitude}
        lng={longitude}
        label={<PinDrop style={{ fontSize: 15 }} />}
      />
    ),
  },
  // {
  //   id: 12,
  //   label: 'Endereço',
  //   value: 'address',
  //   minWidth: 250,
  //   align: 'center',
  // },
  {
    id: 13,
    label: 'Satellite',
    default: true,
    value: 'satellite',
    name: 'satellite',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <Satellite fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ satellite }: { satellite: string }) => (
      <SatelliteSignal signal={satellite} />
    ),
  },
  {
    id: 14,
    label: 'GSM',
    default: true,
    value: 'signal',
    name: 'signal',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <SignalCellularAlt fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({
      signal,
      manufacturer,
    }: {
      signal: string;
      manufacturer: string;
    }) => <GsmSignalIcon signal={signal} manufacturer={manufacturer} />,
  },
  {
    id: 15,
    label: 'Ignição',
    default: true,
    value: 'ignition',
    name: 'ignition',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <VpnKey fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ ignition }: { ignition: boolean }) => <IsOn isOn={ignition} />,
  },

  {
    id: 16,
    label: 'Velocidade',
    default: true,
    value: 'speed',
    name: 'speed',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <Speed fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ speed }: { speed: string }) => <SpeedKmh speed={speed} />,
  },

  // {
  //   id: 18,
  //   label: 'S2',
  //   default: false,
  //   value: 'out2',
  //   name: 'out2',
  //   minWidth: 50,
  //   align: 'center',
  //   alignRow: 'center',
  //   render: ({ out2, model }: { out2: boolean; model: string }) => (
  //     <IsLocked isOn={out2} model={model} />
  //   ),
  // },
  {
    id: 19,
    label: 'Tensão',
    default: true,
    value: 'voltage',
    name: 'voltage',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <FlashOn fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ voltage }: { voltage: string }) => (
      <BatteryVoltage voltage={voltage} />
    ),
  },
  {
    id: 20,
    label: 'Bateria',
    default: true,
    value: 'bateryPercent',
    name: 'batery_percent',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <BatteryChargingFull fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({
      bateryPercent,
      manufacturer,
    }: {
      bateryPercent: string;
      manufacturer: string;
    }) => (
      <BatteryPercent
        batteryPercent={bateryPercent}
        manufacturer={manufacturer}
      />
    ),
  },
  {
    id: 17,
    label: 'S1',
    default: true,
    value: 'out1',
    name: 'out1',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ out1, model }: { out1: boolean; model: string }) => (
      <IsLocked isOn={out1} model={model} />
    ),
  },
  {
    id: 21,
    label: 'Tratamento',
    value: 'treatment',
    default: true,
    name: 'treatment',
    description: '',
    align: 'center',
    alignRow: 'center',
    icon: <Build fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ vehicleId }: { vehicleId: string }) => (
      <TreatmentGrid vehicleId={vehicleId} />
    ),
  },
];
