import { makeStyles, Theme } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
};

export const useStyles = makeStyles<Theme, StyleProps>({
  menu: {
    all: 'unset',
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'row' : 'column'),
    gap: ({ isMobile }) => (isMobile ? '6px' : 'normal'),
  },
  listItem: {
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center',
  },
  listIcon: {
    minWidth: 0,
  },
});
