import React, { Suspense } from 'react';
import { Fallback } from 'components/Fallback';
import { Sidebar } from 'components/Sidebar';
import { useMobileResolution } from 'hooks/useMobileResolution';
import { useHistory } from 'react-router-dom';
import { RoutesContainer } from '.';

export function DisplayRoutes() {
  const isMobileResolution = useMobileResolution();
  const { location } = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobileResolution ? 'column-reverse' : 'row',
        height: location.pathname === '/map' ? 'calc(100vh - 52px)' : '100vh',
        justifyContent: isMobileResolution ? 'space-between' : 'normal',
      }}
    >
      <Sidebar />
      <Suspense fallback={<Fallback />}>
        <RoutesContainer />
      </Suspense>
    </div>
  );
}
