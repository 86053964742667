/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */

import React, { useContext } from 'react';
import Slide from '@material-ui/core/Slide';
import { IconButton, ClickAwayListener } from '@material-ui/core';
import { EventService } from 'services/events.js';
import ReactDOM from 'react-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TVehicle } from 'types/vehicle';
import { differenceInMinutes } from 'date-fns';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { dialogContext } from 'pages/SharedTracking/SharedTrackerMap';
import { AxiosResponse } from 'axios';
import { SubInfosEvents } from '../SubInfosEvents';
import { useStyles } from './styles';

type EventProps = {
  imei: string;
  carInfo: TVehicle;
};

type EventPayload = {
  event: string;
  dateTime: string;
  dateSystem: string;
};

export function EventTab({ imei, carInfo }: EventProps) {
  const classes = useStyles();
  const { dialogIsOpen } = useContext(dialogContext);
  const [checked, setChecked] = React.useState(false);
  const [event, setEvent] = React.useState<EventPayload[]>();
  const openEventTabs = () => {
    setChecked(checked => !checked);
    EventService.eventsByImei({
      imei,
      eventTypes: 'all',
      ignorePending: false,
      read: false,
      treated: 'all',
      limit: 20,
    }).then((r: AxiosResponse<any>) =>
      setEvent(r.data.payload as EventPayload[]),
    );
  };
  const closeCard = () => {
    setChecked(false);
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button
          type="button"
          onClick={openEventTabs}
          className={`${
            dialogIsOpen ? classes.buttonEventsHideCard : classes.buttonEvents
          }`}
        >
          <PriorityHighIcon
            className={`${dialogIsOpen ? '' : classes.iconEvents}`}
          />
        </button>
      </div>
      {ReactDOM.createPortal(
        <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
          <div className={classes.wrapper}>
            <ClickAwayListener onClickAway={closeCard}>
              <div className={classes.paper}>
                <section className={classes.headerEventCard}>
                  <h1 className={classes.textHeaderEventCard}>Eventos</h1>
                  {/* <img
                    src="/assets/imgs/event_header_illustration.png"
                    alt="Imagem ilustrando eventos"
                  /> */}
                </section>
                <section className={classes.eventsContent}>
                  {event
                    ? event?.map(({ event, dateSystem, dateTime }) => {
                        const sanitizedFooter = dateSystem || dateTime;
                        return (
                          <SubInfosEvents
                            differenceInMinutes={`${differenceInMinutes(
                              new Date(),
                              new Date(sanitizedFooter),
                            )}`}
                            dateTime={sanitizedFooter}
                            model={carInfo.model}
                            plate={carInfo.plate}
                            typeEvent={event}
                          />
                        );
                      })
                    : Array.from({ length: 10 }).map(() => <SubInfosEvents />)}
                </section>
              </div>
            </ClickAwayListener>
            <IconButton type="button" onClick={closeCard}>
              <ArrowBackIosIcon style={{ color: '#fff' }} />
            </IconButton>
          </div>
        </Slide>,
        document.body,
      )}
    </>
  );
}
