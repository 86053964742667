import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import { ErrorBoundary } from 'components/Error/index.js';

import withClearCache from 'components/ClearCache';
import { DisplayRoutes } from 'routes/DisplayRoutes';
import { useEffect } from 'react';
import { REACT_APP_LOGO_BASE_URL } from 'config';
import AppProvider from './context';

const Main = (): JSX.Element => {
  useEffect(() => {
    const subDomain = window.location.hostname.split('.').slice(0, 2).join('.');
    const dynamicFaviconLink = `${REACT_APP_LOGO_BASE_URL}${subDomain}_favicon.ico`;
    const faviconElement = document.querySelector(
      "link[rel='icon']",
    ) as HTMLLinkElement;

    if (faviconElement) {
      const img = new Image();
      img.onload = () => {
        faviconElement.href = dynamicFaviconLink;
      };
      img.onerror = () => {
        console.warn('dynamic favicon not found');
      };
      img.src = dynamicFaviconLink;
    }
  }, []);

  return (
    <BrowserRouter>
      <AppProvider>
        <CssBaseline />
        <ErrorBoundary>
          <DisplayRoutes />
        </ErrorBoundary>
      </AppProvider>
    </BrowserRouter>
  );
};

const ClearCacheComponent = withClearCache(Main);

function App(): JSX.Element {
  return <ClearCacheComponent />;
}

export default App;
