import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  noSignal: {
    color: theme.palette.error.main,
    [theme.breakpoints.only('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 13,
    },
  },
  lowSignal: {
    color: theme.palette.warning.main,
    [theme.breakpoints.only('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 13,
    },
  },
  highSignal: {
    color: theme.palette.success.main,
    [theme.breakpoints.only('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 13,
    },
  },
}));
