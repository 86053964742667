import SpeedIcon from '@material-ui/icons/Speed';
import { TPosition } from 'types/position';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { useStyles } from './styles';

type VehicleInfoProps = {
  cardInfo: TPosition;
};

export function SubInfosCard({ cardInfo }: VehicleInfoProps) {
  const classes = useStyles();
  if (!cardInfo) return null;
  return (
    <>
      <div className={classes.subInfosCardContent}>
        <p className={classes.text}>
          <VpnKeyIcon style={{ width: 20, height: 15 }} />{' '}
          {cardInfo.ignition ? 'Ligado' : 'Desligado'}
        </p>
        <p className={classes.text}>
          <SpeedIcon style={{ fontSize: 15 }} /> {cardInfo.speed}Km/h
        </p>
        <p className={classes.text}>
          <QueryBuilderIcon style={{ width: 20, height: 15 }} />{' '}
          {cardInfo.dateTime}
        </p>
      </div>
    </>
  );
}
