import { createContext, useContext, useState, useCallback } from 'react';
import { useToast } from 'context/toastProvider';

import { storage } from 'utils/storage';
import { AuthUser } from 'types';
import { useMutation } from 'react-query';
import { UserSettingsService } from 'services/userSettings';
import { TMapSettings, TUserSettings } from 'types/user.js';
import { deviceGridColumns } from 'config/configColumns/gridDevices';
import { communicationGridColumns } from 'config/configColumns/gridCommunication';
import { eventGridColumns } from 'config/configColumns/gridEvents';
import { Session } from '../services/session';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface SignInCredentials {
  login: string;
  password: string;
  rememberMe: boolean;
}

interface AuthContextData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: AuthUser;
  setUser: (user: AuthUser) => void;
  login: (credentials: SignInCredentials) => Promise<void>;
  logout: () => void;
  isAuthenticated: boolean;
  loading: boolean;
  success: boolean;
  updateUserSettings: (data: TUserSettings) => Promise<void>;
  defaultUserSettings: any;
}

const defaultUserSettings = {
  theme: 'dark',
  grid: {
    eventGridColumns: [
      ...eventGridColumns.filter(e => e.default).map(e => e.name),
    ],
    deviceGridColumns: [
      ...deviceGridColumns.filter(e => e.default).map(e => e.name),
    ],
    communicationGridColumns: [
      ...communicationGridColumns.filter(e => e.default).map(e => e.name),
    ],
  },
  map: {
    theme: 'dark',
    marker: 'car',
    traceType: 'hours',
    traceValue: 2,
    traceEnabled: true,
    cluster: true,
  },
  emergencyEvents: {
    notification: true,
    sound: true,
  },
  simpleEvents: {
    eventTypes: [],
  },
};

export const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { addToast } = useToast();
  const localUser = storage.getItem({ key: 'user', storageType: 'local' });
  const localToken = storage.getItem({ key: 'token', storageType: 'local' });
  const localRefreshToken = storage.getItem({
    key: 'refresh_token',
    storageType: 'local',
  });

  const sessionUser = storage.getItem({ key: 'user', storageType: 'session' });
  const sessionToken = storage.getItem({
    key: 'token',
    storageType: 'session',
  });
  const sessionRefreshToken = storage.getItem({
    key: 'refresh_token',
    storageType: 'session',
  });

  const userSettings = storage.getItem({
    key: 'settings',
    storageType: 'session',
  });

  const [user, setUser] = useState<AuthUser>(() => {
    if (localUser && localToken && localRefreshToken) {
      return {
        ...localUser,
        token: localToken,
        refreshToken: localRefreshToken,
        userSettings,
      };
    }

    if (sessionUser && sessionToken && sessionRefreshToken) {
      return {
        ...sessionUser,
        token: sessionToken,
        refreshToken: sessionRefreshToken,
        userSettings,
      };
    }
    return {} as AuthUser;
  });

  const isAuthenticated = !!Object.keys(user).length;

  const mutation = useMutation(
    async (formData: SignInCredentials) => {
      const data = await Session.login(formData);
      return data;
    },
    {
      onSuccess: (response, { rememberMe }) => {
        const { user, token, refreshToken } = response.data?.payload || {};
        const userSettings: TUserSettings = {
          grid: {
            communicationGridColumns:
              user.userSettings?.grid?.communicationGridColumns ??
              defaultUserSettings?.grid?.communicationGridColumns,
            deviceGridColumns:
              user.userSettings?.grid?.deviceGridColumns ??
              defaultUserSettings?.grid?.deviceGridColumns,
            eventGridColumns:
              user.userSettings?.grid?.eventGridColumns ??
              defaultUserSettings.grid?.eventGridColumns,
            fenceGridColumns: user.userSettings?.grid?.fenceGridColumns ?? [],
          },
          map:
            user.userSettings?.map ?? (defaultUserSettings.map as TMapSettings),
          emergencyEvents: {
            notification:
              user.userSettings?.emergencyEvents?.notification !== undefined
                ? user.userSettings?.emergencyEvents?.notification
                : defaultUserSettings.emergencyEvents?.notification,
            sound:
              user.userSettings?.emergencyEvents?.sound !== undefined
                ? user.userSettings?.emergencyEvents.sound
                : defaultUserSettings.emergencyEvents.sound,
          },
          simpleEvents:
            user.userSettings?.simpleEvents ?? defaultUserSettings.simpleEvents,
          theme:
            user.userSettings?.theme ??
            (defaultUserSettings.theme as 'dark' | 'light'),
        };

        if (userSettings?.map && userSettings.map.cluster === undefined) {
          userSettings.map.cluster = true;
        }

        setUser({
          ...user,
          token,
          refreshToken,
          userSettings,
        });

        storage.setItem({
          key: 'settings',
          storageType: 'session',
          values: userSettings,
        });
        if (rememberMe) {
          storage.setItem({ key: 'user', storageType: 'local', values: user });
          storage.setItem({
            key: 'token',
            storageType: 'local',
            values: token,
          });
          storage.setItem({
            key: 'refresh_token',
            storageType: 'local',
            values: refreshToken,
          });
        } else {
          storage.setItem({
            key: 'user',
            storageType: 'session',
            values: user,
          });
          storage.setItem({
            key: 'token',
            storageType: 'session',
            values: token,
          });
          storage.setItem({
            key: 'refresh_token',
            storageType: 'session',
            values: refreshToken,
          });
        }

        if (user.isMasterCompany) {
          window.location.href = '/dashboard';
        }
      },
      onError: (error: any) => {
        console.log(error);
        const message = error.response?.data?.message || error.message;
        addToast('error', message);
      },
    },
  );

  const login = async ({ login, password, rememberMe }: SignInCredentials) => {
    try {
      await mutation.mutateAsync({
        login,
        password,
        rememberMe,
      });
      mutation.reset();
    } catch (error) {
      console.log(error);
    }
  };

  const logout = useCallback(async () => {
    storage.clearAll();
    window.location.href = '/';
  }, []);

  const updateUserSettings = useCallback(
    async (data: TUserSettings) => {
      await UserSettingsService.updateByUserId(user.id, data);
      const update = user.userSettings ?? ({} as TUserSettings);
      if (data.grid) {
        update.grid = {
          ...user.userSettings?.grid,
          ...data.grid,
        };
      }
      if (data.theme) {
        update.theme = data.theme;
      }
      if (data.map) {
        update.map = data.map;
      }
      if (data.emergencyEvents) {
        update.emergencyEvents = data.emergencyEvents;
      }
      if (data.simpleEvents) {
        update.simpleEvents = data.simpleEvents;
      }
      storage.setItem({
        key: 'settings',
        storageType: 'session',
        values: update,
      });
      setUser(prev => ({ ...prev, userSettings: { ...update } }));
    },
    [user],
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading: mutation.isLoading,
        success: mutation.isSuccess,
        login,
        logout,
        updateUserSettings,
        user,
        setUser,
        defaultUserSettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthPovider');
  }

  return context;
}

export { AuthProvider, useAuth };
