import { useRef } from 'react';

type Props = {
  mapsInstance?: typeof google.maps;
  mapElement?: google.maps.Map;
};

export type CoordsProps = {
  lat: number;
  lng: number;
};

export function useCarPolyline({ mapElement, mapsInstance }: Props) {
  const polylineCarRef = useRef<{ polyline?: google.maps.Polyline }>({
    polyline: undefined,
  });
  function createTraceCarPolyline(path: Array<CoordsProps>) {
    if (polylineCarRef.current.polyline) {
      polylineCarRef.current.polyline.setMap(null);
    }
    if (mapsInstance && mapElement) {
      polylineCarRef.current.polyline = new mapsInstance.Polyline({
        map: mapElement,
        path,
        geodesic: true,
        strokeColor: '#09ACFB',
        strokeOpacity: 4.0,
        strokeWeight: 3,
      });
      polylineCarRef.current.polyline?.setMap(mapElement);
    }
  }

  return { createTraceCarPolyline, polylineCarRef };
}
